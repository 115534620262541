import React from 'react'
import './auth.css'
import Logo from '../../../assets/images/logo/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik';
import { VerifySchema } from '../../../schemas';
import CustomInput from '../../../components/FormElements/CustomInput'
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { reset, verify } from '../../../feature/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BtnLoader } from '../../../components/BtnLoader';

function Verify() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    const ResendOTP = () => {
        alert('Hello world')
    }

    useEffect(() => {

        if (isError) {
            toast.error(message)
        }

        if (isSuccess) {
            toast.success(message)
            navigate('/dashboard', { replace: true })
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    return (

        <div class="main-wrapper log-wrap">

            <div class="row">

                <div class="col-md-6 login-bg d-flex align-items-center">

                    <div className="welcome-login text-white">

                        <h4 className='mb-4'> Welcome Back to Run Delivery . </h4>

                        <p className='fw-light'> Business plan draws on a wide range of knowledge from different business disciplines. Business draws on a wide range of different business .</p>

                    </div>

                </div>

                <div class="col-md-6 login-wrap-bg">

                    <div class="login-wrapper">

                        <div class="loginbox">

                            <div class="img-logo">

                                <img src={Logo} class="img-fluid" alt="Logo" />

                                <div class="back-home">
                                    <Link to="/">Back to Home</Link>
                                </div>

                            </div>

                            <h1 className='mb-4'> Verify Your Account </h1>

                            <Formik
                                initialValues={{
                                    otp: '',
                                    phone_number: '',
                                }}
                                validationSchema={VerifySchema}
                                onSubmit={(values, actions) => {
                                    dispatch(verify(values))
                                }}
                            >

                                {(props) => (

                                    <>

                                        <Form autoComplete='off'>

                                            <div class="form-group">
                                                <label class="form-control-label"> OTP CODE </label>
                                                <CustomInput name="otp" type="text" placeholder="OTP Code" />
                                            </div>

                                            <div class="form-group">
                                                <label class="form-control-label"> Phone number </label>
                                                <CustomInput name="phone_number" type="tel" placeholder="Phone Number" />
                                            </div>

                                            <div class="d-grid">
                                                <button class="btn btn-sm btn-primary fw-light mt-3" type="submit" disabled={isLoading}> {isLoading ? <BtnLoader /> : 'Verify Account '} </button>
                                            </div>

                                        </Form>

                                        {/* <button onClick={ResendOTP} className="btn btn-dark mt-3 m-auto text-center btn-sm w-100">Resend OTP</button> */}

                                    </>

                                )}

                            </Formik>

                        </div>

                        <div class="google-bg text-center">

                            <p class="mb-0">I don't have an account? <Link className="fw-bold" to="/register">Sign Up</Link></p>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    )
}

export default Verify
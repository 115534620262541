import React from 'react'
import Hero from '../../assets/images/hero_3.png'
import './banner.css'
import PlayStore from '../../assets/images/PlayStore.png'
import AppStore from '../../assets/images/AppStore.png'

function Banner() {
    return (

        <section class="banner_container" data-jarallax data-speed="0.3">

            <div class="container position-relative zindex-5 m-auto">

                <section class="banner app-download-area mb-5">

                    <div class="row align-items-center">

                        <div class="col-lg-6 col-md-12 mb-5">
                            <div class="app-download-content">

                                <h2 class="text-light">We Are Africa's foremost dispatch hailing app</h2>

                                <p class="fw-light mt-3 text-muted">Get connected within minutes to thousands of dispatchers across our digital network, and start smiling to hassle-free deliveries, all from the comfort of your phone.</p>

                                <div class="call_to_action my-4">

                                    <div class="d-flex align-item-center">

                                        <a href='https://play.google.com/store/apps/details?id=com.rundeliverys.run_mobile' target='_blank' rel='noreferrer' className='pe-4'>
                                            <img width={130} src={PlayStore} alt="" />
                                        </a>

                                        <a href='https://apps.apple.com/ng/app/run-dispatch-hailing-app/id1546020518' target='_blank' rel='noreferrer'>
                                            <img width={130} src={AppStore} alt="" />
                                        </a>

                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 app_img d-flex justify-content-end">
                            <div class="app-image">
                                <div class="main-image">
                                    <img width="550" src={Hero} class="" alt="image" />
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


            </div>

        </section>

    )
}

export default Banner
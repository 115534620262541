import React from 'react'
import './auth.css'
import Logo from '../../../assets/images/logo/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik';
import { LoginSchema } from '../../../schemas';
import CustomInput from '../../../components/FormElements/CustomInput'
import CustomPassword from '../../../components/FormElements/CustomPassword'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { login, reset } from '../../../feature/auth/authSlice';
import { BtnLoader } from '../../../components/BtnLoader';

function Login() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {

        if (isError) {
            toast.error(message)
        }

        if (message == 'An OTP has been sent to your registered phone number. Please verify your phone number.') {
            navigate('/verify', { replace: true })
        }

        if (user) {
            toast.success('You have signed in successfully')
            navigate('/dashboard', { replace: true })
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    return (

        <div class="main-wrapper log-wrap">

            <div class="row">

                <div class="col-md-6 login-bg d-flex align-items-center">

                    <div className="welcome-login text-white">

                        <h4 className='mb-4'> Welcome Back to Run Delivery. </h4>

                        <p className='fw-light'> Business plan draws on a wide range of knowledge from different business disciplines. Business draws on a wide range of different business .</p>

                    </div>

                </div>

                <div class="col-md-6 login-wrap-bg">

                    <div class="login-wrapper">

                        <div class="loginbox">

                            <div class="img-logo">

                                <img src={Logo} class="img-fluid" alt="Logo" />

                                <div class="back-home">
                                    <Link to="/">Back to Home</Link>
                                </div>

                            </div>

                            <h1 className='fw-bold mb-4'>Sign In</h1>

                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                validationSchema={LoginSchema}
                                onSubmit={(values, actions) => {
                                    dispatch(login(values))
                                }}
                            >

                                {(props) => (

                                    <Form autoComplete='off'>

                                        <div class="form-group">
                                            <label class="form-control-label">Email</label>
                                            <CustomInput name="email" type="email" placeholder="Email address" />
                                        </div>

                                        <div class="form-group">
                                            <label class="form-control-label">Password</label>
                                            <CustomPassword name="password" placeholder="Password" />
                                        </div>

                                        <div class="d-grid">
                                            <button class="btn btn-sm btn-primary fw-light fs-sm mt-3" type="submit" disabled={isLoading}> {isLoading ? <BtnLoader /> : 'Sign In '} </button>
                                        </div>

                                    </Form>

                                )}

                            </Formik>

                        </div>

                        <div class="google-bg text-center">

                            <p class="mb-2">Forgot your Password? <Link className="fw-bold" to="/forgotpassword">  Reset Password </Link></p>

                            <p class="mb-0">I don't have an account? <Link className="fw-bold" to="/register">Register</Link></p>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    )
}

export default Login
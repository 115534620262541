import React from 'react'
import Logo from './../../assets/images/logo/logo.png'
import { useState } from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import { BsBell } from 'react-icons/bs'
import { useSelector } from 'react-redux'

function Header() {

    const [open, setOpen] = useState(true)

    const { userProfile } = useSelector((state) => state.auth)

    return (

        <>

            {open && <Sidebar />}

            <header class="default heade-sticky">
                <div class="un-title-page">
                    <img src={Logo} alt="Logo" width={70} />
                </div>
                <div class="un-block-right">
                    <div class="un-notification">
                        <Link to="/dashboard" aria-label="activity">
                            <i class="ri-notification-line"> <BsBell /></i>
                        </Link>
                        <span class="bull-activity"></span>
                    </div>
                    <div class="un-user-profile">
                        <Link to="/profile" aria-label="profile">
                            <picture>
                                <img class="img-avatar" src={userProfile?.user?.avatar} alt="img account" />
                            </picture>
                        </Link>
                    </div>

                    <div onClick={() => setOpen(true)} class="menu-sidebar">
                        <button type="button" name="sidebarMenu" aria-label="sidebarMenu" class="btn"
                            data-bs-toggle="modal" data-bs-target="#mdllSidebar-connected">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="9.3" viewBox="0 0 19 9.3">
                                <g id="Group_8081" data-name="Group 8081" transform="translate(-329 -37)">
                                    <rect id="Rectangle_3986" data-name="Rectangle 3986" width="19" height="2.3"
                                        rx="1.15" transform="translate(329 37)" fill="#222032" />
                                    <rect id="Rectangle_3987" data-name="Rectangle 3987" width="19" height="2.3"
                                        rx="1.15" transform="translate(329 44)" fill="#222032" />
                                </g>
                            </svg>
                        </button>
                    </div>

                </div>
            </header>

        </>

    )
}

export default Header
import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'

function ErrorPage() {
    return (

        <div className='body'>

            <Header />

            {/* BANNER */}

            <div className="error_page">

                <div className="banner m-auto">

                    <div className="container px-3 px-lg-0">

                        <h1 className='text-center fw-bold text-light'>Page not Found</h1>

                        <div class="help pt-3 m-auto text-center">
                            <div className="row">
                                <div className="col-lg-6 m-auto">
                                    <Link to="/"> <button className="btn btn-primary"> Back To Home </button> </Link>
                                </div>
                            </div>
                        </div>

                        <div className="row m-auto illutsration_image text-center">

                            <div className="col-md-6 m-auto">
                                <img className='illustration_1' src="/images/empty_cart.png" alt="" />
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <Footer />

        </div>

    )
}

export default ErrorPage
import React from 'react'
import { BsCalendar2DateFill } from 'react-icons/bs'
import { TbTruckDelivery } from 'react-icons/tb'
import { FaUsers } from 'react-icons/fa'
import { MdDateRange } from 'react-icons/md'
import CounterUp from './CounterUp'
import './style.css'

function Counter() {
    return (

        <div class="container">

            <div class="row pt-5 pb-4">

                <div class="col-lg-3 col-md-6 col-6">
                    <div class="counter-item">
                        <div class="icon-element mb-4">
                            <BsCalendar2DateFill />
                        </div>
                        <h2 class="counter-number counter mb-2"> <CounterUp number={3} /> </h2>
                        <p class="counter-title">Years in Business</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-6">
                    <div class="counter-item mt-lg-5">
                        <div class="icon-element mb-4">
                            <TbTruckDelivery />
                        </div>
                        <h2 class="counter-number counter mb-2">  <CounterUp number={156876} />+ </h2>
                        <p class="counter-title">Deliveries</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-6">
                    <div class="counter-item">
                        <div class="icon-element mb-4">
                            <FaUsers />
                        </div>
                        <h2 class="counter-number counter mb-2"> <CounterUp number={71540} /> </h2>
                        <p class="counter-title">Active Happy Users</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-6">
                    <div class="counter-item mt-lg-5">
                        <div class="icon-element mb-4">
                            <MdDateRange />
                        </div>
                        <h2 class="mb-2 fw-bold counter-number counter"> <CounterUp number={216} />+ </h2>
                        <p class="counter-title"> Daily Deliveries </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Counter
import React from 'react'
import { BsFillPatchCheckFill } from 'react-icons/bs'
import PlayStore from '../assets/images/PlayStore.png'
import AppStore from '../assets/images/AppStore.png'
import Phone from '../assets/images/mobile_2.png'

function DownloadSteps() {
    return (

        <div class="content trd-content">
            <div class="container">
                <div class="row align-items-center justify-content-between">

                    <div class="col-lg-6">
                        <div class="info">

                            <div class="section-head style-4">
                                <small class="title_small">RUN IS EASY</small>
                                <h2 class="mb-30 text-light fw-bold"> Steps to use run app </h2>
                            </div>

                            <ul>

                                <li class="d-flex align-items-center text-white mb-4">
                                    <i class="me-2 lh-1 color-blue4"><BsFillPatchCheckFill /></i>
                                    <h6 class="fw-bold">Download and open the app</h6>
                                </li>

                                <li class="d-flex align-items-center text-white mb-4">
                                    <i class="me-2 lh-1 color-blue4"><BsFillPatchCheckFill /></i>
                                    <h6 class="fw-bold">Signup and Log in</h6>
                                </li>

                                <li class="d-flex align-items-center text-white mb-4">
                                    <i class="me-2 lh-1 color-blue4"><BsFillPatchCheckFill /></i>
                                    <h6 class="fw-bold">Enter your pickup and drop off locations</h6>
                                </li>

                                <li class="d-flex align-items-center text-white mb-4">
                                    <i class="me-2 lh-1 color-blue4"><BsFillPatchCheckFill /></i>
                                    <h6 class="">Dispatch and Deliver in style Your everyday time, money, comfort, and safety matter.</h6>
                                </li>

                            </ul>

                            <div class="call_to_action my-4">

                                <div class="d-flex align-item-center">

                                    <a href='https://play.google.com/store/apps/details?id=com.rundeliverys.run_mobile' target='_blank' rel='noreferrer' className='pe-4'>
                                        <img width={130} src={PlayStore} alt="" />
                                    </a>

                                    <a href='https://apps.apple.com/ng/app/run-dispatch-hailing-app/id1546020518' target='_blank' rel='noreferrer'>
                                        <img width={130} src={AppStore} alt="" />
                                    </a>

                                </div>

                            </div>


                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="img mb-30 mb-lg-0">
                            <img src={Phone} alt="" />
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default DownloadSteps
import React from 'react'
import Avatar from '../../assets/dashboard/images/avatar.jpg'
import { FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout, reset } from '../../feature/auth/authSlice';
import CurrencyFormat from '../../components/CurrencyFormat'

const Sidebar = () => {

    const dispatch = useDispatch()

    const { userProfile } = useSelector((state) => state.auth)

    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
    }

    return (

        <div class="modal sidebarMenu -left fade" id="mdllSidebar-connected" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">

                    <div class="modal-header d-block pb-1">

                        <div class="un-user-profile">
                            <div class="image_user">
                                <img src={userProfile?.user?.avatar} alt="" />
                            </div>
                            <div class="text-user">
                                <h6> { userProfile?.user?.first_name } { userProfile?.user?.last_name } </h6>
                                <p> User ID: { userProfile?.user?.id } </p>
                            </div>
                        </div>

                        <button type="button" class="btn btnClose" data-bs-dismiss="modal" aria-label="Close">
                            <i class="ri-close-fill"> <FaTimes /> </i>
                        </button>

                        <div class="cover-balance mt-4">
                            <div class="un-balance">
                                <div class="content-balance">
                                    <div class="head-balance">
                                        <h4>My Balance</h4>
                                        <div class="btn link-addBalance">
                                            <i class="ri-add-fill"></i>
                                        </div>
                                    </div>
                                    <p class="no-balance"> <CurrencyFormat price={ userProfile?.user?.wallet } /> </p>
                                </div>
                            </div>
                            <Link to='/wallet'> <button type="button" class="btn btn-sm-size bg-white text-dark rounded-pill"
                                data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#mdllUploadItem">
                                Top Up
                            </button> </Link>
                        </div>

                    </div>

                    <div class="modal-body">
                        <ul class="nav flex-column -active-links mt-3">

                            <li class="nav-item">
                                <Link class="nav-link" to="/dashboard">
                                    <span class="title_link">Dashboard</span>
                                </Link>
                            </li>

                            <li class="nav-item">
                                <Link class="nav-link" to="/profile">
                                    <span class="title_link">Profile</span>
                                </Link>
                            </li>

                            <li class="nav-item">
                                <Link class="nav-link" to="/orders">
                                    <span class="title_link">Orders</span>
                                </Link>
                            </li>

                            <label class="title__label">other</label>

                            <li class="nav-item">
                                <Link class="nav-link" to="/contact">
                                    <span class="title_link">Contact Us</span>
                                </Link>
                            </li>

                            <li class="nav-item">
                                <Link class="nav-link" to="/contact">
                                    <span class="title_link">Help Center</span>
                                </Link>
                            </li>

                        </ul>
                    </div>

                    <div class="modal-footer">
                        <button onClick={onLogout} className="btn btn-dark py-2 w-100"> Logout </button>
                        {/* <div class="em_darkMode_menu">
                            <label class="text" for="switchDark">
                                <h3>Dark Mode</h3>
                                <p>Browsing in night mode</p>
                            </label>
                            <label class="switch_toggle toggle_lg theme-switch" for="switchDark">
                                <input type="checkbox" class="switchDarkMode theme-switch" id="switchDark"
                                    aria-describedby="switchDark" />
                                <span class="handle"></span>
                            </label>
                        </div> */}
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Sidebar
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import Condition from './pages/Conditions'
import Home from './pages/Home'
import Contact from './pages/Contact'
import Rep from './pages/Rep'
import { Toaster } from 'react-hot-toast';
import Investment from './pages/Investment'
import RiderForRun from './pages/RiderForRun'
import Whatsapp from './components/Whatsapp'
import ErrorPage from './pages/ErrorPage'
import RequireAuth from './components/dashboard/RequireAuth'
import Login from './pages/dashboard/Auth/Login'
import Register from './pages/dashboard/Auth/Register'
import ForgotPasswordAuth from './pages/dashboard/Auth/Forgot'
import Reset from './pages/dashboard/Auth/Reset'
import Index from './pages/dashboard/Index'
import Verify from './pages/dashboard/Auth/Verify'
import Profile from './pages/dashboard/Auth/Profile'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getuser, reset } from './feature/auth/authSlice'
import OrdersPage from './pages/dashboard/OrdersPage'
import OrderDetails from './pages/dashboard/orderDetails'
import Wallet from './pages/dashboard/Wallet'
import Paystack from './components/Paystack'
import { getLatestOrder } from './feature/orders'
import ActiveOrder from './pages/dashboard/ActiveOrder'
import CompletedTrip from './pages/dashboard/CompletedTrip'
import axios from './app/api/axios'

function App() {

  const dispatch = useDispatch()
  const { user, userProfile } = useSelector((state) => state.auth)
  const { latestOrder } = useSelector((state) => state.orders)

  const [ countries, setCountries ] = useState([])

  const fetchCountry = async () => {
    const response = await axios.get('/user/get/country')
    setCountries(response.data)
}

  useEffect(() => {

    dispatch(getuser(user?.token))
    dispatch(getLatestOrder(user?.token))

    setInterval(() => {
      dispatch(getLatestOrder(user?.token))
    }, 30000 );

    fetchCountry()
    dispatch(reset())
  }, [dispatch, user])

  return (

    <>

      <Routes>


        {/* public routes */}

        <Route path='/' element={<Home />} />
        <Route path='/terms' element={<Condition />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/rep-for-run' element={<Rep />} />
        {/* <Route path='/investment' element={<Investment />} /> */}
        <Route path='/ride-for-run' element={<RiderForRun />} />

        {/* 404 */}

        <Route path='*' element={<ErrorPage />} />

        <Route path="/" element={<Layout />}>

          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register countries={countries} />} />
          <Route path='/forgotpassword' element={<ForgotPasswordAuth />} />
          <Route path='/resetpassword' element={<Reset />} />
          <Route path='/verify' element={<Verify />} />

          {/* protected routes */}

          <Route element={<RequireAuth />}>

            {latestOrder?.trip?.status == 'cancelled' || latestOrder?.trip?.payment_received == '1' ? (

              <Route path='/dashboard' element={<Index country={userProfile?.user?.country?.code} />} />

            )

              :

              latestOrder?.trip?.status == 'completed' ? (

                <Route path='/dashboard' element={<CompletedTrip country={userProfile?.user?.country?.code} order={latestOrder?.trip} />} />

              ) :

                latestOrder?.trip?.status == 'not active' || latestOrder?.trip?.status == 'paired' || latestOrder?.trip?.status == 'active'? (

                  <Route path='/dashboard' element={<ActiveOrder order={latestOrder?.trip} token={user?.token} />} />

                )

                : 

                <Route path='/dashboard' element={<Index />} />

            }

            <Route path='/profile' element={<Profile countries={countries} country={userProfile?.user?.country} />} />
            <Route path='/orders' element={<OrdersPage />} />
            <Route path='/order/:id' element={<OrderDetails />} />

            {/* Wallet */}
            <Route path='/wallet' element={<Wallet />} />
            <Route path='/pay' element={<Paystack />} />

          </Route>

        </Route>

      </Routes>

      {/* <Whatsapp /> */}

      <Toaster />

    </>

  )
}

export default App
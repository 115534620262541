import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ContactForm from '../components/ContactForm'

function Contact() {

    return (

        <div className='body_3'>

            {/* TOP NAV */}

            {/* <Nav /> */}

            {/* HEADER */}

            <Header />

            <ContactForm />

            <div class="map w-100 mt-5">
                <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.547092201467!2d3.388761314149782!3d6.452139425780356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b104961cf2b%3A0x39ef6ffafa5803d0!2sBookshop%20House%2C%2050%2F52%20Nnamdi%20Azikwe%20St%2C%20Lagos%20Island%20102273%2C%20Lagos!5e0!3m2!1sen!2sng!4v1663673288703!5m2!1sen!2sng" height="450" style={{ border: '0' , width: '100%'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            
            <Footer />

        </div>

    )
}

export default Contact
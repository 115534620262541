import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { BtnLoader } from './BtnLoader'
import toast from 'react-hot-toast';


function ContactForm() {

    const [ name, setName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ subject, setSubject ] = useState('')
    const [ msg, setMsg ] = useState('')

    const [ loading, setLoading ] = useState(false)


    const handleSubmit = async (e) => {

        e.preventDefault()

        setLoading(true)
        
        const data = {
            name, email, phone, subject, msg
        }

        await axios.post('https://dashboard.rundeliverys.com/api/v1/user/contact', data).then(function (response) {
            toast.success(response.data.message)
            setLoading(false)
            setName('')
            setEmail('')
            setPhone('')
            setSubject('')
            setMsg('')
          })
          .catch(function (error) {
            toast.error('Something went wrong !');
            setLoading(false)
            setName('')
            setEmail('')
            setPhone('')
            setSubject('')
            setMsg('')
          });

    }

    return (

        <div class="container content mt-90">

            <div class="row justify-content-center">

                <div class="col-lg-8">

                    <form class="form" onSubmit={handleSubmit} >

                        <div class="row">

                            <div class="col-lg-6 mb-2">
                                <div class="form-group mb-20">
                                    <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} class="form-control" placeholder="Name" required />
                                </div>
                            </div>

                            <div class="col-lg-6 mb-2">
                                <div class="form-group mb-20">
                                    <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)}  class="form-control" placeholder="Email Address *" required />
                                </div>
                            </div>

                            <div class="col-lg-6 mb-2">
                                <div class="form-group mb-20">
                                    <input type="tel" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)}  class="form-control" placeholder="Phone Number" required />
                                </div>
                            </div>

                            <div class="col-lg-6 mb-2">
                                <div class="form-group mb-20">
                                    <input type="text" name="phone" value={subject} onChange={(e) => setSubject(e.target.value)} class="form-control" placeholder="Subject" required />
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <textarea value={msg} onChange={(e) => setMsg(e.target.value)}  rows="10" name="message" class="form-control p-3" placeholder="How can we help you?" required></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 text-center text-light">
                                <div class="form-check d-inline-flex mt-30 mb-30">
                                    <input class="form-check-input me-2 mt-0" type="checkbox" value="" id="flexCheckDefault" required />
                                    <label class="form-check-label small" for="flexCheckDefault">
                                        By submitting, i’m agreed to the <Link to='/terms' class="text-decoration-underline fw-bold">Terms &amp; Conditons</Link>
                                    </label>
                                </div>
                            </div>

                            <div class="col-lg-12 text-center">
                                <button type='submit' className='btn rounded-pill btn-primary fs-sm sm-butn fw-bold text-light'> { loading ? <BtnLoader /> : 'Send Your Request' } </button>
                            </div>

                        </div>
                    </form>

                </div>

            </div>


        </div>

    )
}

export default ContactForm
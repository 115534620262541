import React from 'react'
import Header from '../components/Header/Header'
import Nav from '../components/Header/Nav'
import Footer from '../components/Footer/Footer'

function Condition() {
  return (

    <div className='body'>

      <Header />


      <div className="container text-light mt-70 fw-light">

        <div class="section-content">

          <div class="row">

            <div class="item" id="sustainability">

              {/* PART 1 */}

              <div className="part_1 mb-5">

                <h4 class="fw-bold mb-4">Part A</h4>

                <div class="contents">

                  <ol>
                    <li>The data subject by clicking ‘I AGREE’ button agrees to the terms and conditions
                      &amp; privacy policy which are listed hereunder and any other that may be
                      included in the future.</li>
                  </ol>
                  <ol>
                    <li>Data to be collected.</li>
                  </ol>
                  <ol>
                    <li>By clicking the link supplied to the data subject’s email, the data subject
                      consents to the privacy policy of the data administrator/controller and any
                      other privacy policy that may be included in the future.</li>
                  </ol>
                  <ol>
                    <li>The personal data of the data subject that will be collected are as follows:
                    </li>
                  </ol>
                  <ol>
                    <li>Name, Address, Email, Phone Number, GPS Location, Picture, Contacts in Phone,
                      Private property owned and or in possession by the data subject.</li>
                    <li>The purpose of collecting the data is to ensure that the data
                      administrator/controller knows the data subject, the type of goods being
                      transported, the location the data subject is and the destination the goods is
                      getting to.</li>
                  </ol>
                  <ul>
                    <li>There are technical tools used to collect and store the information</li>
                  </ul>
                  <ol>
                    <li>Logistics companies, Professional Motorcycle riders, other users of the
                      Application shall have access to all the data supplied by the data subject.</li>
                    <li>The data collected shall be stored and processed in accordance with the data
                      law/regulation currently in force.</li>
                    <li>Where the law/regulation leaves the storage and processing of any data subject
                      to the agreement of the data administrator/controller and the data subject, the
                      data subject consents that it would be at the discretion of the data
                      administrator/controller.</li>
                  </ol>
                  <ol>
                    <li>Remedy</li>
                  </ol>
                  <ol>
                    <li>The Data subject shall have the right to lodge a complaint to the data
                      administrator/controller for any grievance that the data subject has.</li>
                    <li>The Data administrator/controller shall within seven working days respond to the
                      grievance.</li>


                    <li>Where the grievance is such that is against the Logistics Company or dispatch
                      rider, the loss or claim shall be against the Logistics Company or dispatch
                      rider.</li>
                    <li>The Data administrator/controller shall not be held responsible for any loss or
                      damage that arose from the transportation of the goods, the
                      behavior/character/conditions of the end user or the dispatch rider/logistics
                      company.</li>
                  </ol>
                  <ol>
                    <li>Foreign Country.</li>
                  </ol>
                  <p>Data subject information may be transferred to a foreign country or a third party and
                    the data subject consent</p>
                  <ol>
                    <li>The data subject shall be notified of all data collected by the data
                      administrator/controller at the beginning of the sign up. However, any further
                      notification the data subject demands is deemed to be excessive and shall
                      attract a fee/charge to be determined by the data administrator/controller.</li>
                    <li>The Data shall be stored for as long as the data subject uses and maintains an
                      account with the Application and after the data subject deletes the account, the
                      data shall be kept for as long as any applicable law/regulation provides that
                      such data be kept.</li>
                    <li>Contact details:</li>
                  </ol>
                  <p>Any information or grievance can be addressed to the data controller/administration:
                    Run Technologies Limited, email address: <a href="mailto:admin@rundeliverys.com">admin@rundeliverys.com</a> phone
                    number;08065576282 physical address: 13<sup>th</sup> Floor, Bookshop House, 50/52
                    Broad street, Lagos.</p>
                  <p> Or the data protection officer: name: Seun Akoyon, email address: <a href="mailto:seun.akoyon@rundeliverys.com">seun.akoyon@rundeliverys.com</a>
                    phone number: 08065576282, physical address: 13<sup>th</sup> Floor, Bookshop House,
                    50/52 Broad Street, Lagos.</p>
                  <ol>
                    <li>The data controller/administrator shall not be held responsible for any breach
                      or exposure in the personal data collected due to the negligence of the data
                      subject or any third party.</li>
                  </ol>


                  <hr />
                </div>


              </div>


              {/* PART 2 */}

              <div className="part_2 my-5">

                <h4 class="fw-bold mb-4">Part B</h4>

                <div class="contents">
                  <p>The following Policy also describes the information Run Technologies processes to
                    support the product and services.</p>
                  <ol>
                    <li><strong>INFORMATION COLLECTED</strong></li>
                  </ol>
                  <p>To provide these Products/services, Run Technologies must process information about
                    you. The type of information that we collect depends on how you use our Products.
                  </p>
                  <ol>
                    <li><strong>Things that you and others do and provide.</strong></li>
                  </ol>
                  <ol>
                    <li>Information and content you provide: We collect the content, communications and
                      other information you provide when you use our Products, including when you sign
                      up for an account, create or share content and message or communicate with
                      others</li>
                    <li>Networks and connections: We collect information about the people, accounts, and
                      groups that you are connected to and how you interact with them across our
                      Products, such as people you communicate with the most or groups that you are
                      part of.</li>
                    <li>Your usage<strong>:</strong> We collect information about how you use our
                      Products, such as the kind of package you deliver if you insert it.</li>
                  </ol>
                  <ol>
                    <li><strong>Information from partners.</strong></li>
                  </ol>
                  <p>Advertisers, developers and publishers can send us information through <a href="https://web.facebook.com/help/331509497253087">our Tools</a> that they
                    use, including our social plugins. These partners provide information about your
                    activities – including information about your device, websites you visit, purchases
                    you make, the ads you see and how you use their services – We also receive
                    information about your online and offline actions and purchases from third-party
                    data providers who have the rights to provide us with your information. Partners
                    receive your data when you visit or use their services, or through third parties
                    that they work with.</p>
                  <ol start="2">
                    <li><strong>Usage of Information</strong></li>
                  </ol>
                  <p>We use the information that we have (subject to choices you make) as described below,
                    and to provide and support the Products and related services described. Here’s
                    how:</p>
                  <ol>
                    <li><strong>Provide, personalize and improve our Products: </strong>We use the
                      information we have to deliver our Products, including to personalize features
                      and content on our Products. To create personalized Products that are unique and
                      relevant to you, we use your connections, preferences, interests and activities
                      based on the data that we collect and learn from you and others (including any
                      <a href="https://web.facebook.com/policy.php?_rdc=1&amp;_rdr#data-special-protections">data
                        with special protections</a> you choose to provide); how you use and
                      interact with our Products; and the people, places or things that you’re
                      connected to and interested in on our Products.
                    </li>
                    <li><strong>Information across our Products, services and devices:</strong> We
                      connect information about your activities on different Products, services and
                      devices to provide a more tailored and consistent experience on all our Products
                      that you use, wherever you use them. We can also make your experience more
                      seamless, for example, by automatically filling in your registration information
                      (such as your phone number) when you sign up on our account.</li>
                  </ol>
                  <ol>
                    <li><strong>Location-related information:</strong>We use<u> </u><a href="https://web.facebook.com/about/basics/manage-your-privacy/location">location-related
                      information</a> – such as your current location, where you live, the places
                      you like to go, and the businesses and people you’re near – to provide,
                      personalize and improve our Products, <a href="https://web.facebook.com/about/ads">including ads</a>, for you and
                      others. Location-related information can be based on things such as precise
                      device location, IP addresses and information from your and others’ use of
                      our Products (such as check-ins or events you attend).</li>
                    <li><strong>Product research and development:</strong>We use the information we have
                      to develop, test and improve our Products, including by conducting surveys and
                      research, and testing and troubleshooting new products and features.</li>
                    <li><strong>Face recognition:</strong>If you have it turned on, we may use face
                      recognition technology to recognize you in photos, videos and camera
                      experiences. If we introduce face recognition technology to our product, we will
                      let you know first, and you will have control over whether we use this
                      technology for you.</li>
                    <li><strong>Advertisements and other sponsored content:</strong>We use the
                      information we have about you – including information about your interests,
                      actions and connections – to select and personalize ads, offers and other
                      sponsored content that we show you.</li>
                    <li><strong>Provide measurement, analytics and other business services:</strong> We
                      use the information we have to help advertisers and other partners measure the
                      effectiveness and distribution of their and our ads and services and understand
                      the types of people who use their and our services and how people interact with
                      their and our services.</li>
                    <li><strong>Promote safety, integrity and security:</strong> We use the information
                      that we have to verify accounts and activity, combat harmful conduct, detect and
                      prevent spam and other bad experiences, maintain the integrity of our Products,
                      and promote safety and security of our Products and services. For example, we
                      use data that we have to investigate suspicious activity or breaches of our
                      Terms or Policies, or to <a href="https://code.facebook.com/posts/286893341840510/under-the-hood-suicide-prevention-tools-powered-by-ai/">detect
                        when someone/the user needs help</a>/attention/service.</li>
                    <li><strong>Communicating with you:</strong> We use the information that we have to
                      send you marketing communications, communicate with you about our Products and
                      let you know about our Policies and Terms. We also use your information to
                      respond to you when you contact us.</li>
                    <li><strong>Research and innovate for social good:</strong> We use the information
                      we have (including from research partners we collaborate with) to conduct and
                      support <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fresearch.fb.com%2F&amp;h=AT1Hp7jyHjtI479cNLGgp7fTKFrJONjU0bpM4q_x6Qo18YAOiNXrmYYcyRhPGjsuX7p8gw6J_uyMn61H2T4nUNhyJfksoNpDtWmeHvRNO502qstIrMihMvRciOAT91L6vzkesi_DwFmJBKxS_oSK3g">research</a>and
                      innovation on topics of general social welfare, technological advancement,
                      public interest, health and well-being. For example, <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fresearch.fb.com%2Ffacebook-disaster-maps-methodology%2F&amp;h=AT36A5UYeWFaHyr17Yo5hxKXIka9GbQplHaAod8nqI94FXAz1yN8lU6Cx63OdWFmR9C4SCZAlr3B5sD8naLnbjHMEy-bZRT2LPoosi3cyapkRw_eloapTWeMw4hJuVbf9Rig7rJCHTe2D8Rvtch7pA">we
                        analyze information that we have about migration patterns during
                        crises</a>/traffic to aid relief efforts or evade traffic. </li>
                    <li><strong>Sharing of information</strong></li>
                  </ol>
                  <ol>
                    <li>Your information is shared with others in the following ways:</li>
                    <li>Sharing/Communicating on our Products/services</li>
                    <li>People and accounts that you share and communicate with</li>
                    <li>When you communicate using our Products, you give irrevocable consent to access
                      your contacts, audio and location. We also let other accounts see your location
                    </li>
                    <li><a href="https://web.facebook.com/help/203805466323736?ref=dp">Public
                      information</a> can be seen by anyone, on our Products/services.</li>
                    <li>Information about your active status or presence on our Products.</li>
                    <li>People in your networks can see signals telling them whether you are active on
                      our Products/services, including whether you are currently active on or when you
                      last used our Products/services.</li>
                    <li>Apps, websites and third-party integrations on or using our Products: When you
                      choose to use third-party apps, websites or other services that use, or are
                      integrated with, our Products, they can receive information about your location,
                      post or share.</li>
                    <li>New owner: If the ownership or control of all or part of our Products or their
                      assets changes, we shall transfer your information to the new owner.</li>
                    <li>Sharing with third-party partners: We work with third-party partners who help us
                      provide and improve our Products or who use our products to grow their
                      businesses, which makes it possible to operate our companies and provide
                      services to people around the world. We may sell any of your information to any
                      entity. We also impose strict restrictions on how our partners can use and
                      disclose the data we provide. Here are the types of third parties that we share
                      information with:</li>
                  </ol>
                  <ol>
                    <li>Partners who use our analytics services: We provide aggregated statistics and
                      insights that help people and businesses understand how people are engaging with
                      our products, as well as aggregate demographic and other information that helps
                      them understand interactions with their account.</li>
                    <li>Advertisers: We provide advertisers with reports about the kinds of people
                      seeing their ads and how their ads are performing, we share the information in
                      such a way that helps them better understand their audience.</li>
                  </ol>
                  <ul>
                    <li>Measurement partners: We share information about you with entities that
                      aggregate it to provide analytics and measurement reports to our partners.</li>
                  </ul>
                  <ol>
                    <li>Partners offering goods and services in our Products: When you subscribe to
                      receive content, or get something from another user in our Products, the content
                      creator or user can receive your public information and other information that
                      you share with them, as well as the information needed to complete the
                      transaction, including contact details.</li>
                    <li>Vendors and service providers: We provide information and content to vendors and
                      service providers who support our business, such as by providing technical
                      infrastructure services, analyzing how our Products are used, providing customer
                      service, facilitating payments or conducting surveys.</li>
                    <li>Researchers and academics: We also provide information and content to research
                      partners and <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fresearch.fb.com%2Fprograms%2F&amp;h=AT13L3CjM3R9g8o9t5VyXWG7z8N20G2ztfTl_WIxxBCCmDKLxqXuqZBD0nidEgaack4y4eAjYMU3hpj29YyCtNyoE94Ffc0l5SOiMH4ZnIO3Ze9ZDUax-o4SBTKyaCwI9Jb7NeSp46bXmcv5zPFwQQ">academics</a>to
                      conduct research that advances scholarship and innovation that supports our
                      business or mission and enhances discovery and innovation on topics of general
                      social welfare, technological advancement, public interest, health and
                      well-being.</li>
                  </ol>
                  <ul>
                    <li>Law enforcement or Court orders: We share information with law enforcement and
                      or in response to court orders.</li>
                  </ul>
                  <ol start="4">
                    <li><strong>Response to legal requests and prevention of harm</strong></li>
                  </ol>
                  <p>We access, preserve and share your information with regulators, law enforcement or
                    others:</p>
                  <ol>
                    <li>In response to a legal request (e.g. a search warrant, court order or subpoena)
                      if we have a good-faith belief that the law requires us to do so. This may
                      include responding to legal requests from jurisdictions outside of Nigeria when
                      we have a good-faith belief that the response is required by law in that
                      jurisdiction, affects users in that jurisdiction and is consistent with
                      internationally recognized standards.</li>
                    <li>When we have a good-faith belief that it is necessary to: detect, prevent and
                      address fraud, unauthorized use of the Products, breaches of our Terms or
                      Policies, or other harmful or illegal activity; to protect ourselves (including
                      our rights, property or Products), you or others, including as part of
                      investigations or regulatory enquiries; or to prevent death or imminent bodily
                      harm. For example, if relevant, we provide information to and receive
                      information from third-party partners about the reliability of your account to
                      prevent fraud, abuse and other harmful activity on and off our Products.</li>
                    <li>Information we receive about you (including financial transaction data related
                      to purchases made) can be accessed and preserved for an extended period when it
                      is the subject of a legal request or obligation, governmental investigation or
                      investigations of possible violations of our terms or policies, or otherwise to
                      prevent harm. We also retain information from accounts disabled for term
                      breaches for the minimum period required by statute to prevent repeat abuse or
                      other term breaches.</li>
                    <li><strong>Operation and Transfer of Data</strong></li>
                  </ol>
                  <p>We share information globally, locally, and externally with our partners and with
                    those you connect and share with around the world in accordance with this Policy.
                    Your information may, for example, be transferred or transmitted to, or stored and
                    processed in Nigeria or other countries outside of where you live for the purposes
                    as described in this Policy. These data transfers are necessary to provide the
                    services in this product, and to globally operate and provide our Products to you.
                  </p>
                  <ol start="6">
                    <li><strong>Notification of change in policy</strong></li>
                  </ol>
                  <p>We’ll notify you before we make changes to this Policy and give you the
                    opportunity to review the revised Policy before you choose to continue using our
                    Products.</p>
                  <ol start="7">
                    <li><strong>Terms of Service</strong></li>
                  </ol>
                  <ol>
                    <li>You cannot use our product/services IF</li>
                  </ol>
                  <ol>
                    <li>We’ve previously disabled your account for violations of our Terms or
                      Policies.</li>
                    <li>You are prohibited from receiving our products, services or software under
                      applicable laws.</li>
                  </ol>
                  <ol>
                    <li>We want people to use our product/service to facilitate their business and
                      transport their goods, but not at the expense of the safety and well-being of
                      others or the integrity of our community/society/nation. You therefore agree not
                      to engage in the conduct described below (or to facilitate or support others in
                      doing so):</li>
                  </ol>
                  <ol>
                    <li>You shall not use our Products to do or share anything:</li>
                  </ol>
                  <ol>
                    <li>That breaches these Terms, Conditions and data policy.</li>
                    <li>That is unlawful, misleading, discriminatory or fraudulent.</li>
                    <li>That infringes or violates someone else’s rights, including their
                      intellectual property rights.</li>
                  </ol>
                  <ol>
                    <li>You shall not upload viruses or malicious code, or do anything that could
                      disable, overburden or impair the proper working or appearance of our Products.
                    </li>
                    <li>You shall not access or collect data from our Products using automated means
                      (without our prior permission) or attempt to access data that you do not have
                      permission to access.</li>
                  </ol>
                  <ol>
                    <li>We can remove or restrict access to content that is in violation of these
                      provisions: If we remove content that you have shared in violation of our
                      conditions, we’ll let you know and explain any options you have to request
                      another review, unless you seriously or repeatedly violate these Terms or if
                      doing so may expose us or others to legal liability; harm our community of
                      users; compromise or interfere with the integrity or operation of any of our
                      services, systems or Products; where we are restricted due to technical
                      limitations; or where we are prohibited from doing so for legal reasons.</li>
                  </ol>
                  <ol start="10">
                    <li><strong> The permissions and consent given by user</strong></li>
                  </ol>
                  <p>We need certain permissions from you to provide our services:</p>
                  <ol>
                    <li>Permission to use content that you create and share: To provide our services,
                      you have given us some legal permissions/consent to use some of the
                      content/information you have uploaded/imputed. This is solely for the purposes
                      of providing and improving our Products and services as described in this data
                      policy/terms and conditions</li>
                  </ol>
                  <ol>
                    <li>Specifically, when you share, post or upload content that is covered by
                      intellectual property rights on or in connection with our Products, you grant us
                      a non-exclusive, transferable, sub-licensable, royalty-free and worldwide
                      license to host, use, distribute, modify, run, copy, publicly perform or
                      display, translate and create derivative works of your content.</li>
                  </ol>
                  <ol>
                    <li>When you delete content, it’s no longer visible to other users; however,
                      it may continue to exist elsewhere on our systems where:</li>
                  </ol>
                  <ol>
                    <li>Immediate deletion is not possible due to technical limitations</li>
                    <li>your content has been used by others in accordance with this terms and
                      conditions/data policy and they have not deleted it (in which case, the consent
                      will continue to apply until that content is deleted); or</li>
                  </ol>
                  <ul>
                    <li>Where immediate deletion would restrict our ability to:
                      <ol>
                        <li>investigate or identify illegal activity or breaches of our Terms and
                          Policies (for example, to identify or investigate misuse of our Products
                          or systems);</li>
                        <li>comply with a legal obligation, such as the preservation of evidence; or
                        </li>
                        <li>comply with a request of a judicial or administrative authority, law
                          enforcement or a government agency;</li>
                      </ol>
                    </li>
                  </ul>
                  <p>in which case, the content will be retained for no longer than is necessary for the
                    purposes for which it has been retained (the exact duration will vary on a
                    case-by-case basis).</p>
                  <p>In each of the above cases, the consent will continue until the content has been
                    fully deleted.</p>
                  <ol>
                    <li>Permission to use your name, profile picture and information about your actions
                      with ads and sponsored content: You give us permission to use your name and
                      profile picture and information about actions that you have taken on our
                      product/service next to or in connection with ads, offers and other sponsored
                      content that we display across our Products, without any compensation to you.
                      For example, we may show other users that you are interested in an advertised
                      event or have liked a content created by a brand that has paid us to display its
                      ads.</li>
                  </ol>
                  <ol>
                    <li>Permission to update software that you use or download: If you download or use
                      our software, you give us permission to download and install updates to the
                      software where available.</li>
                  </ol>
                  <ol start="11">
                    <li><strong>Limits on intellectual property</strong></li>
                  </ol>
                  <p>If you use content covered by intellectual property rights that we have and make
                    available in our Products (for example, images, designs, videos or sounds that we
                    provide, which you add to content that you create or share on our product/service),
                    we retain all rights to that content. You can only use our copyrights and trademarks
                    as expressly permitted by us or with our prior written permission. You must obtain
                    our written permission (or permission under an open-source license) to modify,
                    create derivative works of, or otherwise attempt to extract source code from us.</p>
                  <p><strong> </strong></p>
                  <ol start="12">
                    <li><strong> Additional provisions</strong></li>
                  </ol>
                  <ol>
                    <li>Updating our Terms</li>
                  </ol>
                  <ol>
                    <li>We work constantly to improve our services and develop new features to make our
                      Products better for you and our community. As a result, we may need to update
                      these Terms from time to time to accurately reflect our services and practices.
                      We will only make changes if the provisions are no longer appropriate or if they
                      are incomplete, and only if the changes are reasonable and take due account of
                      your interests.</li>
                    <li>We will notify you (for example, by email or through our Products) within the
                      time prescribed by law before we make changes to these Terms and give you an
                      opportunity to review them before they go into effect, unless changes are
                      required by law. Once any updated Terms are in effect, you will be bound by them
                      if you continue to use our Products.</li>
                    <li>We hope that you will continue using our Products, but if you do not agree to
                      our updated Terms and no longer want to use our product, you can deactivate your
                      account at any time.</li>
                  </ol>
                  <ol>
                    <li>Account suspension or termination</li>
                  </ol>
                  <ol>
                    <li>If we determine that you have clearly, seriously or repeatedly breached our
                      Terms or Policies, including in particular our data policy/terms and conditions,
                      we may suspend or permanently disable access to your account. We may also
                      suspend or disable your account if you repeatedly infringe other people’s
                      intellectual property rights or where we are required to do so for legal
                      reasons.</li>
                    <li>Where we take such action, we’ll let you know and explain any options you
                      have to request a review, unless doing so may expose us or others to legal
                      liability; harm our community of users; compromise or interfere with the
                      integrity or operation of any of our services, systems or Products; or where we
                      are restricted due to technical limitations; or where we are prohibited from
                      doing so for legal reasons.</li>
                    <li>If you delete or we disable your account, these Terms shall terminate as an
                      agreement between you and us, but some other provisions will be in place.</li>
                  </ol>
                  <ol>
                    <li> Limits on liability</li>
                  </ol>
                  <ol>
                    <li>We work hard to provide the best Products we can and to specify clear guidelines
                      for everyone who uses them. Our Products, however, are provided “as
                      is”, and we make no guarantees that they will always be safe, secure or
                      error-free, or that they will function without disruptions, delays or
                      imperfections. To the extent permitted by law, we also DISCLAIM ALL WARRANTIES,
                      WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                      FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. We do not control
                      or direct what people and others do or say, and we are not responsible for their
                      actions or conduct (whether online or offline) or any content that they share
                      (including offensive, inappropriate, obscene, unlawful and other objectionable
                      content).</li>
                    <li>We cannot predict when issues may arise with our Products. Accordingly, our
                      liability shall be limited to the fullest extent permitted by applicable law,
                      and under no circumstances will we be liable to you for any lost profits,
                      revenues, information or data, or consequential, special, indirect, exemplary,
                      punitive or incidental damages arising out of or related to these Term, even if
                      we have been advised of the possibility of such damages.</li>
                  </ol>
                  <ol>
                    <li>Disputes</li>
                  </ol>
                  <p>We try to provide clear rules so that we can limit or hopefully avoid disputes
                    between you and us. If a dispute does arise, however the Laws of the Federal
                    Republic of Nigeria applies.</p>
                  <ol>
                    <li> Other</li>
                  </ol>
                  <ol>
                    <li>These Terms and conditions/ Data policy make up the entire agreement between you
                      and the Company regarding your use of our Products/Services. They supersede any
                      prior agreements.</li>
                    <li>Some of the Products that we offer are also governed by supplemental Terms. If
                      you use any of those Products, supplemental terms will be made available and
                      will become part of our agreement with you. To the extent that any supplemental
                      Terms conflict with these Terms, the supplemental Terms shall govern to the
                      extent of the conflict.</li>
                    <li>If any portion of these Terms is found to be unenforceable, the remaining
                      portion will remain in full force and effect. If we fail to enforce any of these
                      Terms, it will not be considered a waiver. Any amendment to or waiver of these
                      Terms must be made in writing and signed by us.</li>
                    <li>You will not transfer any of your rights or obligations under these Terms to
                      anyone else without our consent.</li>
                    <li>These Terms do not confer any third-party beneficiary rights. All of our rights
                      and obligations under these Terms are freely assignable by us in connection with
                      a merger, acquisition or sale of assets, or by operation of law or otherwise.
                    </li>
                    <li>You should know that we may need to change the username for your account in
                      certain circumstances (for example, if someone else claims the username and it
                      appears unrelated to the name that you use in everyday life). We will inform you
                      in advance if we have to do this and explain why.</li>
                    <li>We always appreciate your feedback and other suggestions about our products and
                      services. But you should know that we may use them without any restriction or
                      obligation to compensate you, and we are under no obligation to keep them
                      confidential.</li>
                    <li>We reserve all rights not expressly granted to you.</li>
                    <hr />
                  </ol>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>


      <Footer />

    </div>

  )
}

export default Condition
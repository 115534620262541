import React from 'react'
import CountUp from 'react-countup';

function CounterUp({ number }) {
    return (
        <CountUp start={0}
            end={number}
            separator=","
            enableScrollSpy="true"
            scrollSpyDelay={2}
            useEasing={true}
            duration={5} />
    )
}

export default CounterUp
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './../feature/auth/authSlice'
import ordersReducer from './../feature/orders'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'

const reducers = combineReducers({
  auth: authReducer,     
  orders: ordersReducer,     
 });
 
 const persistConfig = {
     key: 'root',
     version: 1,
     storage
 };
 
 const persistedReducer = persistReducer(persistConfig, reducers);
 
 
 export const store = configureStore({
     reducer: persistedReducer,
     devTools: process.env.NODE_ENV !== 'production',
 });
import React from 'react'
import Hero from '../assets/images/about.png'

function About() {
    return (

        <section class="about mt-30 pt-5">

            <div class="content frs-content">
                <div class="container">

                    <div class="row align-items-center justify-content-between">

                        <div class="col-lg-6 d-none d-lg-block mb-3">
                            <div class="img mb-30 mb-lg-0">
                                <img src={Hero} alt="" />
                            </div>
                        </div>

                        <div class="col-lg-6 mb-3">

                            <div class="info">

                                <h2 class="mb-30 fw-bold text-light">                                About the Run app! </h2>

                                <p class="text mb-30 text-muted">

                                    RUN is the solution and reality to on the spot delivery service and not just the regular same delivery service in Africa. 

                                    <br /> <br />

                                     If you need to send or receive packages from an online vendor, physical markets, large shopping malls, offices and so many others, RUN is your best choice. 

                                     <br /> <br />
                                     
                                     No more disappointments and long delivery hours, waiting and wasting time to even get a dispatch bike, unnecessary commitment to the duress full terms of just a dispatch service provider or booking dispatch bikes at surging, unaffordable and excruciating prices, just let us RUN for you and save your everyday time, money and comfort.
                                     

                                </p>

                                {/* <Link to='/' class="btn rounded-pill bg-blue4 fw-bold text-white">
                            <small> Free Register </small>
                        </Link> */}

                            </div>
                        </div>

                        <div class="col-lg-6 d-lg-none">
                            <div class="img mb-30 mb-lg-0">
                                <img src={Hero} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>

    )
}

export default About
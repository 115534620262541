import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../app/api/axios';

export const getOrder = createAsyncThunk(
    "orders/getOrder",
    async (payload) => {
        const response = await axios.get('/user/get/trip/history', {
            headers: { Authorization: `Bearer ${payload}` }
        });
        return response.data;
    }
);

export const getLatestOrder = createAsyncThunk(
    "orders/getLatestOrder",
    async (payload) => {
        const response = await axios.get('/user/get/latest/trip', {
            headers: { Authorization: `Bearer ${payload}` }
        });
        return response.data;
    }
);

export const cancelOrder = createAsyncThunk("orders/cancelOrder", async (payload, thunkAPI) => {

    const response = await axios.get(`/user/web/cancel/trip/${payload?.id}`, {
        headers: { Authorization: `Bearer ${payload?.token}` }
    });

    return response.data;


});

export const placeOrder = createAsyncThunk(
    "auth/updateProfileImage",
    async (payload, thunkAPI) => {
        try {
            const response = await axios.post('/user/store/trip', payload?.data, {
                headers: { Authorization: `Bearer ${payload?.user?.token}` }
            });
            return response
        } catch (error) {
            const message =
              error.response.data.errors ||
                error.response.data.error || error.response.data.status ||
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


const initialState = {
    orders: [],
    latestOrder: {},
    orderLoading: false,
    orderError: false,
    orderSuccess: false,
    orderMessage: "",
};

const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
        orderReset: (state) => {
            state.orderSuccess = false;
            state.orderError = false;
            state.orderMessage = "";
            state.orderLoading = false;
        },
    },

    extraReducers: {

        [getOrder.pending]: (state, action) => {
            state.orderLoading = true;
        },
        [getOrder.fulfilled]: (state, action) => {
            state.orderLoading = false;
            state.orderSuccess = false;
            state.orders = action.payload;
        },
        [getOrder.rejected]: (state, action) => { },


        [getLatestOrder.pending]: (state, action) => {
            state.orderLoading = true;
        },
        [getLatestOrder.fulfilled]: (state, action) => {
            state.orderLoading = false;
            state.orderSuccess = false;
            state.latestOrder = action.payload;
        },
        [getLatestOrder.rejected]: (state, action) => { },



        [cancelOrder.pending]: (state, action) => {
            state.orderLoading = true;
        },
        [cancelOrder.fulfilled]: (state, action) => {
            state.orderLoading = false;
            state.orderSuccess = true;
            state.orders = action.payload;
            state.orderMessage = action.payload.success
        },
        [cancelOrder.rejected]: (state, action) => { },

        [placeOrder.pending]: (state, action) => {
            state.orderLoading = true;
        },
        [placeOrder.fulfilled]: (state, action) => {
            state.orderLoading = false;
            state.orderSuccess = true;
            console.log(action.payload);
            // state.orderMessage = action.payload
        },
        [placeOrder.rejected]: (state, action) => {
            state.orderLoading = false;
            state.orderError = true;
            state.orderMessage = action.payload
        },

    },
});

export const { orderReset } = orderSlice.actions;
export default orderSlice.reducer;

import React, { useState } from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import InvestmentImage from '../assets/images/investment_run.jpeg'
import axios from 'axios'
import toast from 'react-hot-toast'
import { BtnLoader } from '../components/BtnLoader'

function Rep() {

    const options = [
        { text: 'Call', value: 'Call' },
        { text: 'Text Message', value: 'Text Message' },
        { text: 'Physically', value: 'Physically' }
    ]

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [about, setAbout] = useState('')
    const [target, setTarget] = useState('')
    const [talk, setTalk] = useState('Physically')

    const [loading, setLoading] = useState(false)

    const data = {
        name, email, phone, about, target, talk,
    }

    const handleSubmit = async (e) => {

        e.preventDefault()

        setLoading(true)

        await axios.post('https://dashboard.rundeliverys.com/api/v1/user/sales/rep', data).then(function (response) {
            toast.success(response.data.message)
            setLoading(false)
        })
            .catch(function (error) {
                toast.error('Something went wrong !');
                setLoading(false)
            });

        setName('')
        setEmail('')
        setPhone('')
        setAbout('')
        setTalk('Physically')
        setTarget('')

    }

    return (

        <div className='body_2'>

            {/* HEADER */}

            <Header />

            {/* INVESMENT FORM */}

            <div className="container m-auto row my-5">

                <div className="col-lg-8 m-auto">

                    <div className="card p-3 p-lg-4 mb-3 text-center">
                        <h6 className="fw-bold">RUN SALES REP application form</h6>
                    </div>

                    <div className="card p-3 p-lg-4 mb-3">
                        <img src={InvestmentImage} alt="" />
                    </div>

                    <form onSubmit={handleSubmit}>

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <input type="text" class="form-control shadow-sm" placeholder='Full name' id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                                <label for="name" > Full Name </label>
                            </div>

                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <input type="email" class="form-control shadow-sm" id="email" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <label for="email">Email address</label>
                            </div>

                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <input type="tel" class="form-control shadow-sm" id="phone" placeholder="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                                <label for="phone">Phone number(ONLY whatsapp number)</label>
                            </div>

                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <textarea class="form-control" placeholder="Leave a comment here" id="about" style={{ height: '100px' }} value={about} onChange={(e) => setAbout(e.target.value)} required></textarea>
                                <label for="about">What do you know about RUN</label>
                            </div>
                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <textarea class="form-control" placeholder="Leave a comment here" id="target" style={{ height: '100px' }} value={target} onChange={(e) => setTarget(e.target.value)} required></textarea>
                                <label for="target">What are your daily goals or targets in making money for yourself?</label>
                            </div>
                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <label htmlFor="">How best can you convince someone to be your customer</label>

                            <div className="mt-3">

                                {options.map((option) => (

                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="convince" id={option.text} value={option.value} checked={talk == option.value} onChange={(e) => setTalk(e.target.value)} required />
                                        <label class="form-check-label" htmlFor={option.text}> {option.text} </label>
                                    </div>

                                ))}

                            </div>

                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-4">

                            <button type='submit' className="btn btn-primary btn-sm" disabled={loading}> {loading ? <BtnLoader /> : 'Submit'} </button>

                            <button type="reset" style={{ background: 'none', border: 'none', outline: 'none', color: '#000' }}> Clear Form </button>
                        </div>

                    </form>

                </div>

            </div>

            {/* FOOTER */}

            <Footer />

        </div>

    )
}

export default Rep
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CurrencyFormat from './../../components/CurrencyFormat'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { cancelOrder } from './../../feature/orders'

const DataTable = ({ trips, token }) => {

  const dispatch = useDispatch()

  const handleCancel = (id) => {
    const data = {
       id, token
    }
    dispatch(cancelOrder(data))
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'name', headerName: 'Trip Item', width: 300 },
    { field: 'confirmation_code', headerName: 'Trip Code', width: 100 },
    {
      field: 'price', headerName: 'Trip Price', width: 120, renderCell: (params) => {
        return (
          <div>
            <CurrencyFormat price={params.row.price} />
          </div>
        )
      }
    },
    {
      field: 'distance', headerName: 'Trip Distance', width: 150, valueGetter: (params) =>
        `${params.row.distance} km`
    },
    {
      field: 'status', headerName: 'Status', width: 120, valueGetter: (params) =>
        `${params.row.status == 'not active' ? "Not Active" : params.row.status == 'paired' ? "Paired" : params.row.status == 'active' ? "Active" : params.row.status == 'completed' ? "Completed" : params.row.status == 'cancelled' ? "Cancelled" : 'Delivered'}`
    },
    { field: 'created_at', headerName: 'Trip Date', width: 200 },
    {
      field: 'actions', headerName: 'Actions', width: 250, renderCell: (params) => {
        return (
  
          <div className="d-flex align-items-center">
  
            <Link to={`/order/${params.row.id}`} > <button className='btn edit-btn me-3 m-auto' > <i className="fa-solid fa-eye me-1 "></i> View Trip </button> </Link>
  
            { params.row.status == 'completed' || params.row.status == 'delivered' || params.row.status == 'cancelled' ? '' : <button onClick={() => handleCancel(params.row.id)} className='btn delete-btn' > <i className="fa-solid fa-eye me-1 "></i> Cancel Trip </button>}
  
          </div>
        )
      }
    },
  ];

  return (
    <div style={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={trips}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[8]}
        checkboxSelection
      />
    </div>
  );

}

export default DataTable
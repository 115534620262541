import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BiCloudUpload } from 'react-icons/bi'
import axios from '../app/api/axios'
import toast from 'react-hot-toast'
import { updateProfileImage } from '../feature/auth/authSlice';
import { useDispatch } from 'react-redux';
import { BtnLoader } from './BtnLoader';

const thumbInner = {
    height: '100px',
    width: '100px',
    objectFit: 'cover'
};


function Dropzone({ token, user, isLoading }) {

    const [files, setFiles] = useState([]);
    const dispatch = useDispatch()

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div key={file.name}>
            <img
                src={file.preview}
                className="rounded-circle img-fluid border border-2 border-white"
                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                alt="profile"
                style={thumbInner}
            />
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    });

    const uploadSubmit = async (e) => {

        e.preventDefault()

        if (files.length <= 0) {
            toast.error('Profile Image Feild is empty')
        } else {

            const formData = new FormData()
            formData.append("image", files[0])

            const data = {
                token,
                image: formData
            }

            dispatch(updateProfileImage(data))

            // const response = await axios.post('/user/upload/avatar', formData, {
            //     headers: {
            //         Authorization: `Bearer ${token}`
            //     }
            // })

            setTimeout(() => {
                setFiles([])
            }, 2000);

        }

    }

    return (

        <section className="container text-center m-auto position-relative">

            <form onSubmit={uploadSubmit}>

                <div style={{ cursor: 'pointer' }} {...getRootProps({ className: 'dropzone' })}>
                    <input style={{ outline: 'none' }} className="b-none" {...getInputProps()} />
                    <div className="upload"> <BiCloudUpload /> </div>
                </div>

                {files.length > 0 ? (
                    <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">{thumbs} </div>
                ) : (
                    <div className="mt-n4 mt-lg-n6 mb-lg-0 text-center m-auto">
                        <img className="upload_image img-fluid border border-2 border-white" src={user?.user?.avatar} alt={user?.first_name} />
                    </div>
                )}

                <div className="text-center m-auto mt-4">
                    <button type='submit' class="btn btn-sm btn-primary rounded-pill" disabled={isLoading}> { isLoading ? <BtnLoader /> : 'Update Image' } </button>
                </div>

            </form>

        </section>
    );

}

export default Dropzone

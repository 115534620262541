import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../components/dashboard/Footer'
import Header from '../../components/dashboard/Header'
import BgImage from '../../assets/dashboard/images/BgImage.jpg'
import axios from '../../app/api/axios'
import { useSelector } from 'react-redux'
import { BtnLoader } from '../../components/BtnLoader'
import CurrencyFormat from '../../components/CurrencyFormat'

const OrderDetails = () => {

    const { id } = useParams()
    const { user } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState(false)
    const [trip, setTrip] = useState({})

    useEffect(() => {

        setLoading(true)

        const fetchOrderDetails = async () => {
            const response = await axios.get(`/user/get/trip/history/${id}`, {
                headers: { Authorization: `Bearer ${user?.token}` }
            })

            setTimeout(() => {
                setLoading(false)
                setTrip(response?.data);
            }, 1000)

        }

        fetchOrderDetails()

    }, [user, id])

    return (

        <div className="profile order_details">

            <Header />

            <div className="container-fluid">

                {loading ?

                    (<div className='text-center m-auto' style={{ paddingTop: '200px' }}> <BtnLoader /> </div>) : (

                        <div class="" style={{ paddingTop: '100px' }}>

                            <div class="card">

                                <div class="card-header">
                                    Trip Details
                                </div>

                                <div class="card-body">

                                    <div class="row">

                                        <div class="col-md-4">
                                            <h1 class="text-dark">Trip Item</h1>
                                            <p class="ml-2 mb-5">- {trip.name}</p>

                                            <h1>Started at</h1>
                                            <p class="ml-2 mb-5">- {trip.started_at}</p>

                                            <h1>Trip Price</h1>
                                            <p class="ml-2 mb-5">- <CurrencyFormat price={trip.price} /></p>
                                        </div>

                                        <div class="col-md-4">
                                            <h1>Trip Weight</h1>
                                            <p class="ml-2 mb-5">-  {trip.weight}</p>

                                            <h1>Ended at</h1>
                                            <p class="ml-2 mb-5">- {trip.ended_at}</p>

                                            <h1>Trip Distance</h1>
                                            <p class="ml-2 mb-5">- {trip.distance} km</p>
                                        </div>

                                        <div class="col-md-4">
                                            <h1>Status</h1>
                                            <p class="ml-2 mb-5">- {trip.status}</p>

                                            <h1>Trip Item</h1>
                                            <p class="ml-2 mb-5">- {trip.name}</p>

                                            <h1>Trip Confimation Code</h1>
                                            <p class="ml-2">- {trip.confirmation_code}</p>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div class="card mt-5">
                                <div class="card-header">
                                    Trip Categories
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4 mb-5">

                                            {trip?.trip_categories?.map((categories) => (

                                                <div key={categories.id}>
                                                    <p class="ml-2 mb-5">- {categories.category}</p>
                                                </div>

                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="card mt-5">
                                        <div class="card-header">
                                            Trip Source Location
                                        </div>
                                        <div class="card-body">
                                            <div class="row">

                                                <div class="col-md-12 mb-5" >

                                                    {trip?.locations?.map((location) => (

                                                        location?.pivot?.is_source_destination == 1 && (

                                                            <div key={location.placeId}>

                                                                <h1>Address</h1>
                                                                <p class="ml-2 mb-5">- {location.address}</p>

                                                                <h1>Latitude</h1>
                                                                <p class="ml-2 mb-5">- {location.latitude}</p>

                                                                <h1>Longitude</h1>
                                                                <p class="ml-2 mb-5">- {location.longitude}</p>

                                                                <h1>PlaceId</h1>
                                                                <p class="ml-2">- {location.placeId}</p>

                                                            </div>

                                                        )

                                                    ))}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-8">
                                    <div class="card mt-5">
                                        <div class="card-header">
                                            Trip Final Destinations
                                        </div>
                                        <div class="card-body">
                                            <div class="row">

                                                {trip?.locations?.map((location) => (

                                                    <div class="col-md-4 mb-5" >

                                                        {location?.pivot?.is_source_destination == 0 && (

                                                            <div key={location.placeId}>

                                                                <h1>Address</h1>
                                                                <p class="ml-2 mb-5">- {location.address}</p>

                                                                <h1>Latitude</h1>
                                                                <p class="ml-2 mb-5">- {location.latitude}</p>

                                                                <h1>Longitude</h1>
                                                                <p class="ml-2 mb-5">- {location.longitude}</p>

                                                                <h1>PlaceId</h1>
                                                                <p class="ml-2">- {location.placeId}</p>

                                                            </div>

                                                        )}

                                                    </div>

                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="card mt-5">
                                <div class="card-header">
                                    Driver Information
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <h1>Driver Name</h1>
                                            <p class="text-dark mb-5 ml-3">- {trip?.driver?.user?.first_name} {trip?.driver?.user?.last_name}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h1>Driver Email</h1>
                                            <p class="text-dark mb-5 mb-3">- {trip?.driver?.user?.email}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h1>Driver Phone Number</h1>
                                            <p class="text-dark mb-5 ml-3">- {trip?.driver?.user?.phone_number}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    )}

            </div>

            <Footer />

        </div>

    )
}

export default OrderDetails
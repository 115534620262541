import React from 'react'
import Logo from '../../assets/images/logo/logo.png'

function Footer() {

    const year = new Date().getFullYear()

    return (

        <footer class="style-4 mt-0">
            <div class="container">
                <div class="foot">
                    <div class="row align-items-center">

                        <div class="col-lg-2">
                            <div class="logo">
                                <img width={80} src={Logo} alt="" />
                            </div>
                        </div>

                        <div class="col-lg-4 py-lg-0 py-5">

                            <span className="fw-bold"> ADDRESS: </span>

                            <p className='my-0 py-0 mt-2 fs-sm'>  <span className='fw-bold'> NG:</span> 13th floor Bookshop house cms Lagos Island</p>

                            <p className='my-0 py-0 mt-2 fs-sm'>  <span className='fw-bold'> US:</span>16192 coastal highway, Lewes, Sussex County, Delaware.</p>

                        </div>

                        <div class="col-lg-4 py-lg-0 pb-5">

                            <span className="fw-bold"> PHONE: </span>

                            <p className='my-0 py-0'> <a href="tel:+2348087555000"> <span className='fw-bold'> NG:</span> +234 808 7555 000 </a> </p>

                            <p className='my-0 py-0'> <a href="tel:+250799369253">  <span className='fw-bold'> RW:</span> +250799369253 </a> </p>

                            <p className='my-0 py-0'>  <a href="tel:+13322221801"> <span className='fw-bold'> US:</span> +1 332 222-1801 </a> </p>

                        </div>

                        <div class="col-lg-2 py-lg-0 pb-5">

                            <span className="fw-bold"> EMAIL: </span>
                            <p className='my-0 py-0'> <a href="mailto:hello@rundeliverys.com">Hello@rundeliverys.com</a> </p>

                        </div>

                        {/* <div class="col-lg-2">
                            <div class="dropdown">
                                <button class="icon-25 dropdown-toggle p-0 border-0 bg-transparent rounded-circle img-cover" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="https://w7.pngwing.com/pngs/4/265/png-transparent-translation-english-language-google-translate-dictionary-flags-of-the-world-english-flag-globe-thumbnail.png" alt="" class="me-2" />
                                    <small>English</small>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a class="dropdown-item" href="#">English</a></li>
                                    <li><a class="dropdown-item" href="#">Arabic</a></li>
                                </ul>
                            </div>
                        </div> */}

                    </div>
                </div>
                <div class="copywrite text-center">
                    <small class="small">
                        © {year} Copyrights by <span class="fw-bold"> Run Technologies.</span> All Rights Reserved
                    </small>
                </div>
            </div>
        </footer>

    )
}

export default Footer
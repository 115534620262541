import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import '../assets/dashboard/css/dashboard.css'

const Layout = () => {

    const location = useLocation()
    
    const { user } = useSelector(
        (state) => state.auth)
    

    useEffect(() => {
        if (user) {
            <Navigate to="/login" state={{ from: location }} replace={true} />
        }
    }, [user])

    return <Outlet />


}

export default Layout
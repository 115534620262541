import React, { useState } from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import InvestmentImage from '../assets/images/Invest.jpeg'
import axios from 'axios'
import toast from 'react-hot-toast'
import { BtnLoader } from '../components/BtnLoader'
import bankAccounts from '../data/banks.json'
import { useEffect } from 'react'

function Investment() {

    const options = [
        { text: 'Equity', value: 'Equity' },
        { text: 'Debt (5% ROI Monthly)', value: 'Debt (5% ROI Monthly)' },
        { text: 'Debt (30% ROI @ 6 Months)', value: 'Debt (30% ROI @ 6 Months)' }
    ]

    const [accName, setAccName] = useState('')

    const [loading, setLoading] = useState(false)
    const [bankLoading, setBankLoading] = useState(false)

    const [error, setError] = useState('') //]

    const [name, setName] = useState('')
    const [amount, setAmount] = useState('')
    const [investmentKind, setInvestmentKind] = useState('Equity')
    const [accountNumber, setAccountNumber] = useState('')
    const [accountName, setAccountName] = useState(accName && accName)
    const [bank, setBank] = useState('')
    const [phone, setphone] = useState('')
    const [email, setEmail] = useState('')
    const [kin, setKin] = useState('')
    const [address, setAddress] = useState('')
    const [image, setImage] = useState('')

    const getBankName = async () => {

        if(bank == '' || accountNumber == "" || accountNumber.length < 10){
            setBankLoading(false)
            setError('')
        }

        setBankLoading(true)
        setError('')

        await axios.get(`https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${bank}`, {
            headers: { Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK}` }
        }).then(function (response) {
            setTimeout(() => {
                setBankLoading(false)
                setError('')
                setAccName(response?.data?.data?.account_name)
            }, 300);
        })
            .catch(function (error) {
                setBankLoading(false)
                setAccName('')
                setError(error.response.data.status === false && 'Account Number not is valid')
            });

    }

    useEffect(() => {

        const timer = setTimeout(() => {
            getBankName()
        }, 1000)
    
        return () => clearTimeout(timer)
      }, [bank, accountNumber])

    const handleSubmit = async (e) => {

        e.preventDefault()

        setLoading(true)

        const formData = new FormData();
        formData.append("name", name);
        formData.append("amount", amount);
        formData.append("investmentKind", investmentKind);
        formData.append("accountName", accName);
        formData.append("accountNumber", accountNumber);
        formData.append("bank", bank);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("kin", kin);
        formData.append("address", address);
        formData.append("image", image);

        await axios.post('https://dashboard.rundeliverys.com/api/v1/user/investment', formData).then(function (response) {
            toast.success(response.data.message)
            setLoading(false)
        })
            .catch(function (error) {
                toast.error('Something went wrong !');
                setLoading(false)
            });

    }

    const disableBtn = (name == '' || amount == '' || investmentKind == '' || accountNumber == '' || bank == '' || phone == '' || email == '' || kin == '' || address == '' || image == '' || accName == '') 

    return (

        <div className='body_2'>

            {/* HEADER */}

            <Header />

            {/* INVESMENT FORM */}

            <div className="container m-auto row my-5">

                <div className="col-lg-8 m-auto">

                    <div className="card p-3 p-lg-4 mb-3">
                        <h6 className="fw-bold mb-3">RUN Investment Form</h6>
                        <p className="mb-0">After you fill out this investment form, we will contact you to go over details and confirm receipt of payment before the order is completed. Please pay your investment amount into <span className="fw-bold">0661546979</span>, <span className="fw-bold">RUN TECHNOLOGIES LIMITED, GUARANTY TRUST BANK</span> .</p>
                    </div>

                    <div className="card p-3 p-lg-4 mb-3">
                        <h6 className="mb-4">WELCOME ON BOARD THE LOGISTICS TECHNOLOGY REVOLUTION</h6>
                        <img src={InvestmentImage} alt="" />
                    </div>

                    <form onSubmit={handleSubmit} autoComplete='off' enctype="multipart/form-data">

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <input type="text" class="form-control shadow-sm" placeholder='Full name' value={name} onChange={(e) => setName(e.target.value)} required />
                                <label>What is your name in full (Surname - First name)?</label>
                            </div>

                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <input type="number" class="form-control shadow-sm" placeholder='Amount' value={amount} onChange={(e) => setAmount(e.target.value)} required />
                                <label>How much are you investing?</label>
                            </div>

                        </div>

                        {/* What Kind of Investment do you want to make */}
                        <div className="card p-3 p-lg-4 mb-3">

                            <label htmlFor="">What Kind of Investment do you want to make</label>

                            <div className="mt-3">

                                {options.map((option) => (

                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="convince" id={option.text} value={option.value} checked={investmentKind == option.value} onChange={(e) => setInvestmentKind(e.target.value)} required />
                                        <label class="form-check-label" htmlFor={option.text}> {option.text} </label>
                                    </div>

                                ))}

                            </div>

                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <label htmlFor="">Your Bank Account Details (account name, account number, bank name)</label>

                            <div className="row mt-3">

                                <div className="col-sm-4 mb-2">
                                    <div class="form-floating">
                                        <input type="text" class="form-control shadow-sm" placeholder='Account Number' value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} required />
                                        <label>Account Number</label>
                                    </div>
                                </div>

                                <div className="col-sm-4 mb-2">
                                    <div class="form-floating">
                                        <select class="form-select" value={bank} onChange={(e) => setBank(e.target.value)} disabled={!accountNumber} required>
                                            <option selected value=''>Select Bank</option>
                                            {bankAccounts.map((bank) => (
                                                <option value={bank.code}> {bank.name} </option>
                                            ))}
                                        </select>
                                        <label>Bank Name</label>
                                    </div>
                                </div>

                                {bankLoading ?

                                    <div className="col-sm-4">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" placeholder='Account Name' value={accName} readOnly onChange={(e) => setAccountName(e.target.value)} required />
                                            <label >Account name</label>
                                        </div>
                                    </div>

                                    :

                                    <div className="col-sm-4">
                                        <div class="form-floating">
                                            <input type="text" class="form-control shadow-sm" placeholder='Account Name' value={accName} readOnly onChange={(e) => setAccountName(e.target.value)} required />
                                            <label >Account name</label>
                                        </div>
                                    </div>

                                }


                                <div className="mt-2 text-center m-auto mb-0">
                                    {bankLoading ? <BtnLoader /> : ""}
                                </div>

                                <div className="mt-1 text-center m-auto mb-0 text-danger">
                                    {error != '' ? error : ''}
                                </div>

                            </div>

                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <input type="tel" class="form-control shadow-sm" placeholder='Phone Number' value={phone} onChange={(e) => setphone(e.target.value)} required />
                                <label> Your Phone number </label>
                            </div>

                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <input type="email" class="form-control shadow-sm" placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)} required />
                                <label> Your E-mail address </label>
                            </div>

                        </div>

                        {/* Preferred contact method CheckBox */}

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <input type="text" class="form-control shadow-sm" placeholder='Next of Kin' value={kin} onChange={(e) => setKin(e.target.value)} required />
                                <label> Next of Kin Name </label>
                            </div>

                        </div>

                        <div className="card p-3 p-lg-4 mb-3">

                            <div class="form-floating">
                                <input type="text" class="form-control shadow-sm" placeholder='Address' value={address} onChange={(e) => setAddress(e.target.value)} required />
                                <label> Residential Address </label>
                            </div>

                        </div>

                        {/* Please upload payment receipt */}

                        <div className="card p-3 p-lg-4 mb-3">
                            <label for="formFile" class="form-label">Please upload payment receipt</label>
                            <input class="form-control py-3" type="file" name='image' onChange={(e) => setImage(e.target.files[0])} required />
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-4">

                            <button type='submit' className="btn btn-primary btn-sm" disabled={ disableBtn || loading || bankLoading }> {loading ? <BtnLoader /> : 'Submit'} </button>

                            <button type="reset" style={{ background: 'none', border: 'none', outline: 'none', color: '#000' }}> Clear Form </button>

                        </div>

                    </form>

                </div>

            </div>

            {/* FOOTER */}

            <Footer />

        </div>

    )
}

export default Investment
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CurrencyFormat from '../../components/CurrencyFormat'
import Footer from '../../components/dashboard/Footer'
import Header from '../../components/dashboard/Header'
import { useState } from 'react'
import PayStackPayment from '../../components/Paystack'
import { useEffect } from 'react'
import { getuser, reset } from '../../feature/auth/authSlice'
import toast from 'react-hot-toast'
import Loader from '../../components/Loader'

const Wallet = () => {

  const [amount, setAmount] = useState('')

  const { user, isLoading, message, isError, isSuccess, userProfile } = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  useEffect(() => {

    if (isError) {
      toast.error(message)
    }

    if (isSuccess) {
      toast.success(message)
      dispatch(getuser(user?.token))
    }

    dispatch(reset())
    setAmount('')

  }, [isError, isSuccess, message, dispatch])

  return (

    <div className="">


          <Header />

          <div className="container-fluid">

            <section class="un-my-account" style={{ paddingTop: '100px' }}>

              <div class="body rounded">

                <div class="img-coin-eth">
                  <img className='rounded-pill' style={{ objectFit: 'cover' }} src={userProfile?.user?.avatar} alt="" />
                </div>

                <div class="my-balance-text text-light">
                  <h2 className='text-light'>My Balance</h2>

                  <p className='text-light mt-3'> <CurrencyFormat price={userProfile?.user?.wallet} /> </p>

                  <div className="col-9 col-md-4 m-auto">

                    <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Enter Amount' className="form-control mb-3" />

                    <PayStackPayment amount={amount} user={userProfile?.user} />

                  </div>

                </div>
              </div>

            </section>

          </div>

          <Footer />

    </div>
  )
}

export default Wallet
import React, { useEffect } from 'react'
import './auth.css'
import Logo from '../../../assets/images/logo/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik';
import { ResetPasswordSchema } from '../../../schemas';
import CustomInput from '../../../components/FormElements/CustomInput'
import CustomPassword from '../../../components/FormElements/CustomPassword'
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { reset, resetpassword } from '../../../feature/auth/authSlice';

function Reset() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {

        if (isError) {
            toast.error(message)
        }

        if (isSuccess) {
            navigate('/login')
            toast.success(message)
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    return (

        <div class="main-wrapper log-wrap">

            <div class="row">

                <div class="col-md-6 login-bg d-flex align-items-center">

                    <div className="welcome-login text-white">

                        <h4 className='mb-4'> Welcome Back to Run Delivery . </h4>

                        <p className='fw-light'> Business plan draws on a wide range of knowledge from different business disciplines. Business draws on a wide range of different business .</p>

                    </div>

                </div>

                <div class="col-md-6 login-wrap-bg">

                    <div class="login-wrapper">

                        <div class="loginbox">

                            <div class="img-logo">

                                <img src={Logo} class="img-fluid" alt="Logo" />

                                <div class="back-home">
                                    <Link to="/">Back to Home</Link>
                                </div>

                            </div>

                            <h1 className='mb-4'> Reset Passsword </h1>

                            <Formik
                                initialValues={{
                                    phone_number: '',
                                    otp: '',
                                    password: '',
                                    password_confirmation: '',
                                }}
                                validationSchema={ResetPasswordSchema}
                                onSubmit={(values, actions) => {
                                    dispatch(resetpassword(values))
                                }}
                            >

                                {(props) => (

                                    <Form autoComplete='off'>

                                        <div class="form-group">
                                            <label class="form-control-label">Phone number</label>
                                            <CustomInput name="phone_number" type="tel" placeholder="Phone number" />
                                        </div>

                                        <div class="form-group">
                                            <label class="form-control-label">Reset Token</label>
                                            <CustomInput name="otp" type="text" placeholder="Reset Token" />
                                        </div>

                                        <div class="form-group">
                                            <label class="form-control-label">Password</label>
                                            <CustomPassword name="password" placeholder="Password" />
                                        </div>

                                        <div class="form-group">
                                            <label class="form-control-label">Confirm Password</label>
                                            <CustomPassword name="password_confirmation" placeholder="Confirm Password" />
                                        </div>

                                        <div class="d-grid mt-4">
                                            <button class="btn btn-sm btn-primary fw-light fs-sm" type="submit">Reset Password</button>
                                        </div>

                                    </Form>

                                )}

                            </Formik>

                        </div>

                        <div class="google-bg text-center">

                            <p class="mb-0">I don't have an account? <Link className="fw-bold" to="/register">Sign Up</Link></p>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    )
}

export default Reset
import React, { useEffect } from 'react'
import './auth.css'
import Logo from '../../../assets/images/logo/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik';
import { RegistrationSchema } from '../../../schemas';
import CustomInput from '../../../components/FormElements/CustomInput'
import CustomPassword from '../../../components/FormElements/CustomPassword'
import CustomCheckbox from '../../../components/FormElements/CustomCheckbox';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { register, reset } from '../../../feature/auth/authSlice';
import { BtnLoader } from './../../../components/BtnLoader';
import CustomSelect from '../../../components/FormElements/CustomSelect';

function Register({ countries }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {

        if (isError) {
            toast.error(message)
        }

        if (isSuccess) {
            navigate('/verify', { replace: false })
            toast.success(message)
        }

        if(user){
            navigate('/dashboard', { replace: true } )
          }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    return (

        <div class="main-wrapper log-wrap">

            <div class="row">

                <div class="col-md-6 login-bg d-flex align-items-center">

                    <div className="welcome-login text-white">

                        <h4 className='mb-4'> Welcome to Run Delivery . </h4>

                        <p className='fw-light'> Business plan draws on a wide range of knowledge from different business disciplines. Business draws on a wide range of different business .</p>

                    </div>

                </div>

                <div class="col-md-6 login-wrap-bg">

                    <div class="login-wrapper">

                        <div class="loginbox">

                            <div class="img-logo">

                                <img src={Logo} class="img-fluid" alt="Logo" />

                                <div class="back-home">
                                    <Link to="/">Back to Home</Link>
                                </div>

                            </div>

                            <h1 className='fw-bold mb-3'>Sign up</h1>

                            <Formik
                                initialValues={{
                                    accept: false,
                                    first_name: '',
                                    last_name: '',
                                    email: '',
                                    phone_number: '',
                                    password: '',
                                    country_id: ''
                                }}
                                validationSchema={RegistrationSchema}
                                onSubmit={(values, actions) => {
                                    dispatch(register(values))
                                }}
                            >

                                {(props) => (

                                    <Form autoComplete='off'>

                                        <div className='row'>

                                            <div className='col-lg-6'>

                                                <div class="form-group">
                                                    <label class="form-control-label">First Name</label>
                                                    <CustomInput name="first_name" type="text" placeholder="First name" />
                                                </div>

                                            </div>

                                            <div className='col-lg-6'>

                                                <div class="form-group">
                                                    <label class="form-control-label">Last Name</label>
                                                    <CustomInput name="last_name" type="text" placeholder="Last name" />
                                                </div>

                                            </div>

                                        </div>

                                        <div class="form-group">
                                            <label class="form-control-label">Email</label>
                                            <CustomInput name="email" type="email" placeholder="Email address" />
                                        </div>

                                        <div class="form-group">
                                            <label class="form-control-label">Phone</label>
                                            <CustomInput name="phone_number" type="tel" placeholder="Phone number" />
                                        </div>

                                        <div class="form-group">
                                            <label class="form-control-label">Password</label>
                                            <CustomPassword name="password" placeholder="Password" />
                                        </div>

                                        <div class="form-group">
                                            <label class="form-control-label">Confirm Password</label>
                                            <CustomPassword name="confirmPassword" placeholder="Confirm Password" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="" className="form-control-label"> Country </label>
                                            <CustomSelect name='country_id' type='select'>
                                                <option value="" selected={true} disabled={true}> -- Select Country -- </option>
                                                { countries?.map((data) => (
                                                    <option value={data.id}> { data.name } </option>
                                                )) }
                                            </CustomSelect>
                                        </div>

                                        <div class="form-check remember-me">
                                            <CustomCheckbox name='accept' type="checkbox" />
                                        </div>

                                        <div class="d-grid">
                                            <button class="btn btn-sm btn-primary fw-light fs-sm" type="submit" disabled={isLoading}> { isLoading ? <BtnLoader/> : 'Create Account ' } </button>
                                        </div>

                                    </Form>

                                )}

                            </Formik>

                        </div>

                        <div class="google-bg text-center">
                            <p class="mb-0">Already have an account? <Link className="fw-bold" to="/login">Sign in</Link></p>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    )
}

export default Register
import React from 'react'
import Run from '../assets/images/logo/logo.png'

function Loader() {
    return (

        <div className="">

            <div className="m-auto text-center animation_up_down" style={{ paddingTop: '150px' }}>
                <img width={90} src={Run} alt="" />
            </div>

        </div>

    )
}

export default Loader
import React from 'react'
// import Hero from '../assets/images/faq.png'
import Hero from '../assets/images/hero.png'

function Faq() {
    return (

        <div class="content sec-content mt-90 mb-5">

            <div class="container">

                <div class="row align-items-center justify-content-between">

                    <div class="col-lg-5 d-none d-lg-block">
                        <div class="img mb-30 mb-lg-0">
                            <img src={Hero} alt="" />
                        </div>
                    </div>

                    <div class="col-lg-6">

                        <div class="info">

                            <div class="section-head style-4 mb-5">
                                <h3 class="mb-10 text-light">FREQUENTLY ASKED QUESTIONS</h3>
                            </div>

                            <div class="faq style-3 style-4">

                                <div class="accordion" id="accordionExample">

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading3">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                WHO SHOULD USE RUN?
                                            </button>
                                        </h2>
                                        <div id="collapse3" class="accordion-collapse collapse show" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                Every online shopper, students, professionals, online and social media vendors, offices, businesses, shopping malls, restaurants, boutiques, anyone that wants to send or receive packages around town, Anyone who is tired of paying a lot yet getting poor or average delivery service, Anyone who is tired of being at the mercy of dispatch companies or riders, Anyone who is tired of careless and arrogant delivery riders, Anyone who is short of time and can make use of on the spot demand delivery service, Anyone who likes rest of mind when using a dispatch bike, Anyone who wants more customers and sales, because with RUN you can always deliver NOW!
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading1">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                                HOW ARE THE BIKES ON RUN LIKE?
                                            </button>
                                        </h2>
                                        <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                RUN accommodates thousands of dispatch bikes through our RUN CHAMPION APP, some are owned by individuals, SMEs and some are owned by companies. However, when you book and you are paired with a bike you get all the details of the bike, the person, and company, as the case may be. RUN CHAMPION evaluates strictly every dispatch bike on its platform. They are insured, they undergo regular check-ups, in good shape, to ensure reliability alwaysk
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading2">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                HOW ARE THE RIDERS LIKE?
                                            </button>
                                        </h2>
                                        <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                All run champions riders are trained, punctual, and have certified drivers license, all dispatch are monitored by GPS and are trackable anytime you want to.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="heading3">
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                                WHAT DO WE STAND FOR?
                                            </button>
                                        </h2>
                                        <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                Running for everyone.....everywhere.....every time.
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="col-lg-5 d-lg-none mt-5">
                        <div class="img mb-30 mb-lg-0">
                            <img src={Hero} alt="" />
                        </div>
                    </div>

                </div>

            </div>

        </div>

    )
}

export default Faq
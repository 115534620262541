import React from 'react'
import Faq from '../components/Faq'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import { BsFillPatchCheckFill } from 'react-icons/bs'
import GUARANTEE_FORM from '../assets/RUN-CHAMPION_GUARANTEE_FORM.pdf'
import Image from '../assets/images/mobile_app_1.png'

function RiderForRun() {
  return (

    <div className='body'>

      {/* HEADER */}

      <Header />

      <div class="content trd-content mt-5">
        <div class="container">
          <div class="row align-items-center justify-content-between">

            <div class="col-lg-6 mb-5">
              <div class="info">
                <div class="section-head style-4">
                  <h2 class="mb-20 fw-bold text-light">BECOME A RUN <span>  CHAMPION </span> </h2>
                </div>

                <p class="text mb-10 text-light fw-light">
                  You can make MORE money now, when you deliver with RUN, either as an individual or as a company
                </p>

                <p class="text mb-10 text-light fw-light">
                  What’s the interesting part? You get to choose your own convenient working hours
                </p>

                <ul className='mx-0 px-0 mt-4 text-light'>

                  <li class="d-flex align-items-center op-4">
                    <i style={{ fontSize: '16px' }} class="me-2 lh-1 color-blue4">
                      <BsFillPatchCheckFill />
                    </i>
                    <h6 class="fw-bold">All just at the tap of a button </h6>
                  </li>

                  <p class="text text-light fw-light mt-3">
                    <span className="fw-bold">Note:</span> For individual/independent riders Click on the button below to download the guarantors form
                  </p>

                </ul>

                <div className="d-flex align-items-center">

                  <a href={GUARANTEE_FORM} class="btn rounded-pill bg-blue4 fw-bold text-white mt-20 me-3" download><small> Download Guarantors Form </small> </a>

                  <a href="https://dashboard.rundeliverys.com/ride-for-run" target='_blank' rel="noreferrer" class="btn rounded-pill bg-blue4 fw-bold text-white mt-20">
                    <small> REGISTER NOW </small>
                  </a>

                </div>

              </div>
            </div>

            <div class="col-lg-5">
              <div class="steps-left">

                <img src={Image} alt="" />

              </div>
            </div>

          </div>
        </div>
      </div>

      {/* FAQ's */}
      <Faq />

      <Footer />

    </div>

  )
}

export default RiderForRun
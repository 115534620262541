import React from 'react'
import { BiMapPin } from 'react-icons/bi'
import { IoMdCall } from 'react-icons/io'
import { RiMapPinLine } from 'react-icons/ri'
import CurrencyFormat from '../../components/CurrencyFormat'
import Footer from '../../components/dashboard/Footer'
import Header from '../../components/dashboard/Header'
import BIKE from './../../assets/images/bike.png'
import { FaBoxOpen } from 'react-icons/fa'
import { FiCornerRightDown } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { cancelOrder, getLatestOrder, orderReset } from '../../feature/orders'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'

const ActiveOrder = ({ order, token }) => {

    const dispatch = useDispatch()

    const handleCancel = (id) => {
        const data = {
            id, token
        }

        Swal.fire({
            title: 'Are you sure you want to cancel this dispatch trip?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(cancelOrder(data))
            }
        })
    }

    const { orderSuccess, orderError, orderMessage } = useSelector((state) => state.orders)

    useEffect(() => {

        if (orderSuccess) {
            toast.success(orderMessage)
            dispatch(getLatestOrder(token))
        }

        if (orderError) {
            toast.success(orderMessage)
        }

        if(order?.status == 'active'){
        }

        dispatch(orderReset())

    }, [dispatch, orderSuccess, orderError, orderMessage, order])

    return (

        <div className="place_order_container">

            <Header />

            <div className="container-fluid">

                <div className="row" style={{ paddingTop: '80px' }}>


                    <div className="col-lg-6 order-2 order-lg-0 m-auto">

                        {/* HEADER RIDER */}

                        <div className="d-flex align-items-center justify-content-between me-lg-5 mb-4 bg-dark text-light rounded-5 py-2 px-4">

                            <div className=""> <img width={50} src={BIKE} alt="" /> </div>

                            <div className="fw-bold">

                                {order?.status == 'paired' ?

                                    <div className="d-flex align-items-center">
                                        <div class="spinner-border text-primary me-2" style={{ width: '20px', height: '20px' }} role="status">
                                        </div>
                                        <p class="mt-0 pt-3 pb-0 pe-2 fw-light fs-sm"> Trip Accepted </p>
                                    </div>
                                    

                                    :

                                    order?.status == 'active' ?

                                        <div className="d-flex align-items-center">
                                            <div class="spinner-border text-primary me-2" style={{ width: '20px', height: '20px' }} role="status">
                                            </div>
                                            <p class="mt-0 pt-3 pb-0 pe-2 fw-light fs-sm"> Trip In progress </p>
                                        </div>

                                        :

                                        <div className="d-flex align-items-center">
                                            <div class="spinner-border text-primary me-2" style={{ width: '20px', height: '20px' }} role="status">
                                            </div>
                                            <p class="mt-0 pt-3 pb-0 pe-2 fw-light fs-sm"> Searching.......... </p>
                                        </div>

                                }

                            </div>

                            <div className="fw-bold"> <CurrencyFormat price={order?.price} /> </div>

                        </div>

                        <hr className="w-100" />

                        {/* CONFIRMATION CODE */}

                        <div className="d-flex align-items-center justify-content-between mb-4 me-lg-5">
                            <h6 className="fw-bold"> Confimation Code:  </h6>
                            <h5 className='fw-bold'>{order?.confirmation_code}</h5>
                        </div>

                        <hr className="w-100" />


                        {/* ITEM */}

                        <div className='d-flex align-items-center'>

                            <h4 className='mb-0 pb-0 pe-3 text-primary'> <FaBoxOpen /> </h4>
                            <p class="mt-0 pt-3 pe-2 fw-bold"> - {order?.name} </p>
                            <h5 className='pt-3'> <FiCornerRightDown /> </h5>

                        </div>

                        {/* SOURCE */}

                        {order?.locations?.map((location) => (

                            location?.pivot?.is_source_destination == 1 && (

                                <div key={location?.placeId} className='d-flex align-items-center'>

                                    <h4 className='mb-0 pb-0 pe-3 text-primary'> <BiMapPin /> </h4>
                                    <p class="mt-0 pt-3">- {location?.address}</p>

                                </div>

                            )

                        ))}

                        {/* DESTINATION */}

                        {order?.locations?.map((location) => (

                            location?.pivot?.is_source_destination == 0 && (

                                <div key={location.placeId} className='d-flex align-items-center'>

                                    <h4 className='mb-0 pb-0 pe-3 text-primary'> <RiMapPinLine /> </h4>
                                    <p class="mt-0 pt-3">- {location.address}</p>

                                </div>

                            )

                        ))}

                        {/* STATUS */}

                        {order?.status == 'paired' || order?.status == 'active' ? (

                            <div className="d-flex align-items-center justify-content-between mt-4">

                                <img width={45} src={BIKE} alt="" className='me-3 rounded' />

                                <div className="">
                                    <h5 className='fw-bold'> {order?.driver?.user?.first_name} {order?.driver?.user?.last_name} </h5>
                                    <p className='pb-0 pt-0 mb-0 fs-sm'> {order?.driver?.plate_no} </p>
                                    <p className='pb-0 pt-0 mb-0'> {order?.driver?.user?.phone_number} </p>
                                </div>

                                <h1 className='ms-3'> <a className='text-dark' href={`tel:${order?.driver?.user?.phone_number}`}> <IoMdCall /> </a> </h1>

                            </div>

                        ) : (
                            <div className='d-flex align-items-center'>
                                <div class="spinner-border text-primary me-3" role="status">
                                </div>
                                <p class="mt-0 pt-3 pe-2"> Searching.......... </p>
                            </div>
                        )}


                        {/* CANCEL TRIP */}
                        {order?.status == 'active' ? '' :  <button className="btn btn-danger text-light mt-4 rounded-5 bg-none" onClick={() => handleCancel(order?.id)}> Cancel Dispatch Trip </button> }


                    </div>

                    {/* <div className="col-lg-6 order-0 order-lg-2 mt-3 rounded">
                        MAP
                    </div> */}

                </div>

            </div>

            <Footer />

        </div>


    )
}

export default ActiveOrder
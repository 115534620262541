import React from 'react'
import Banner from '../components/Banner/Banner'
import About from '../components/About'
import DownloadSteps from '../components/DownloadSteps'
import Header from '../components/Header/Header'
import Faq from '../components/Faq'
import Footer from '../components/Footer/Footer'
import Counter from '../components/Counter/Counter'
import ScreenShot from '../components/ScreenShot'

function Home() {
  return (

    <div className='body'>

      {/* TOP NAV */}

      {/* <Nav /> */}

      {/* HEADER */}

      <Header />

      {/* BANNER */}

      <Banner />

      <Counter />

      <About />

      {/* <ScreenShot /> */}

      {/* <div class="container mb-5">

        <div class="important p-5">

          <div class="row mb-4">

            <div class="col-md-3 mb-4">

              <h4 class="text-light">
                Advantages of Using JaraNetwork
              </h4>

            </div>

            <div class="col-5"></div>

            <div class="col-md-4 mb-4">

              <p class="fs-sm text-light text-right align_Center">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis.
              </p>

            </div>

          </div>

          <div class="row text-center text-light m-auto">

            <div class="col-lg-4 col-md-6 mb-4">

              <div class="card p-4">

                <img class="m-auto mb-4" src="./assets/img/security.png" alt="" />

                <h5 class="mb-0">Safe & Secured</h5>

                <p class="m-auto fs-sm text-muted mt-3">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tems.</p>

              </div>

            </div>

            <div class="col-lg-4 col-md-6 mb-4">

              <div class="card p-4">

                <img class="m-auto mb-4" src="./assets/img/security.png" alt="" />

                <h5 class="mb-0">Simple & Easy </h5>

                <p class="m-auto fs-sm text-muted mt-3">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tems.</p>

              </div>

            </div>

            <div class="col-lg-4 col-md-6 mb-4">

              <div class="card p-4">

                <img class="m-auto mb-4" src="./assets/img/security.png" alt="" />

                <h5 class="mb-0">Multiple Integration</h5>

                <p class="m-auto fs-sm text-muted mt-3">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tems.</p>

              </div>

            </div>

          </div>


        </div>

      </div> */}

      <DownloadSteps />

      {/* FAQ's */}
      <Faq />

      <Footer />

    </div>

  )
}

export default Home
import React, { useEffect } from 'react'
import { MdLocationPin } from 'react-icons/md'
import { IoMdRemoveCircle } from 'react-icons/io'
import { FaBoxOpen, FaSortAmountUpAlt } from 'react-icons/fa'
import { FiPlusCircle } from 'react-icons/fi'
import { BiMapPin } from 'react-icons/bi'
import Multiselect from 'multiselect-react-dropdown'
import { useState } from 'react'
import axios from '../../app/api/axios'
import CurrencyFormat from './../../components/CurrencyFormat'
import { GoogleMap, useJsApiLoader, Autocomplete, DirectionsRenderer } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { getLatestOrder, orderReset, placeOrder } from '../../feature/orders'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Loader from '../../components/Loader'
import { getuser, reset } from '../../feature/auth/authSlice'


const PlaceOrder = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    // Get user Token 
    const { user, userProfile } = useSelector((state) => state.auth)
    const { orderSuccess, orderError, orderMessage } = useSelector((state) => state.orders)

    useEffect(() => {

        if (orderSuccess) {
            toast.success('You have successfully place an order')
            dispatch(getuser(user?.token))
            dispatch(getLatestOrder(user?.token))
        }

        if (orderError) {
            toast.error(orderMessage)
        }

        dispatch(orderReset())
        dispatch(reset())

    }, [dispatch, user, orderSuccess, orderError, orderMessage])


    // MULTIPLE DROP OFF
    const [offOne, setOffOne] = useState(false)

    const [options, setOption] = useState([])
    const [categories, setCategory] = useState([])
    const [sizes, setSizes] = useState([])
    const [size, setSize] = useState('')
    const [payment, setPayment] = useState('')
    const [quantity, setQuantity] = useState('')
    const [name, setName] = useState('')
    const [item2, setItem2] = useState('')

    // INIT DETAILS
    const [addressOne, setAddressOne] = useState(null)
    const [addressTwo, setAddressTwo] = useState(null)
    const [addressThree, setAddressThree] = useState(null)

    useEffect(() => {

        const fetchCategory = async () => {
            const category = []
            const response = await axios.get('/user/get/category')
            const resData = response.data

            for (let i = 0; i < resData.length; i++) {
                category.push(resData[i].name)
            }
            setOption(category)

        }

        const fetchSize = async () => {
            const response = await axios.get(`/user/get/size?country=${userProfile?.user?.country?.code}`)
            setSizes(response.data)
        }

        fetchCategory()
        fetchSize()

    }, [userProfile])

    // IMPLEMENT GOOGLE
    const apiKey = 'AIzaSyBs873IoYFn8xvYPiin_zFwbCMx0KHWRsY';
    const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

    const google = window.google;

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: apiKey,
        libraries: ['places'],
    })

    // ADD FILDS
    const handleCancelBtn = () => {
        setAddressThree(null)
        setDestination2('')
        setItem2('')
        setOffOne(false)
    }

    // GOOGLE MAP OPTION
    const mapOptions = {
        componentRestrictions: { country: `${userProfile?.user?.country?.code}`, },
      fields: ["formatted_address", "geometry", "name"],
    };

    const [autoCompleteOrigin, setAutocompleteOrigin] = useState(null)
    const [origin, setOrigin] = useState('')
    const [autoCompleteDestination, setAutocompleteDestination] = useState(null)
    const [autoCompleteDestination2, setAutocompleteDestination2] = useState(null)
    const [destination, setDestination] = useState('')
    const [destination2, setDestination2] = useState('')

    const onLoadOrigin = (autoC) => setAutocompleteOrigin(autoC)
    const onLoadDestination = (autoC) => setAutocompleteDestination(autoC)
    const onLoadDestination2 = (autoC) => setAutocompleteDestination2(autoC)

    // Calculte total price
    const [totalPrice, setTotalPrice] = useState(0)
    const [distance, setDistance] = useState(0)
    const [duration, setDuration] = useState(0)

    // DESTINATE ON MAP
    const [map, setMap] = useState(null)
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [coordinates, setCoordinates] = useState({})


    const onPlaceChangeOrigin = () => {
        setOrigin(autoCompleteOrigin.getPlace().formatted_address)
    }

    const onPlaceChangeDestinationOne = () => {
        setDestination(autoCompleteDestination.getPlace().formatted_address)
    }

    const onPlaceChangeDestinationTwo = () => {
        setDestination2(autoCompleteDestination2.getPlace().formatted_address)
    }

    useEffect(() => {

        navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => (
            setCoordinates({ lat: latitude, lng: longitude })
        ))

        const fetch = async () => {

            const directionsService = new google.maps.DirectionsService()
            const service = new google.maps.DistanceMatrixService();

            const result = await directionsService.route({
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
            })

            setDirectionsResponse(result)

            const request = {
                origins: [origin],
                destinations: [destination, destination2],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false,
            };

            service.getDistanceMatrix(request).then((response) => {

                // Calculate Distance Matrix
                const distanceCal = response.rows[0].elements;
                const totalDistance = distanceCal.reduce((accumulator, currentValue) => {
                    if (currentValue.status == 'NOT_FOUND') {
                        return accumulator.distance.value + 0
                    } else {
                        return accumulator.distance.value + currentValue.distance.value
                    }
                })
                setDistance(totalDistance)

                // Calculate Duration
                const durationCal = response.rows[0].elements;
                const totalDuration = durationCal.reduce((accumulator, currentValue) => {
                    if (currentValue.status == 'NOT_FOUND') {
                        return accumulator.duration.value + 0
                    } else {
                        return accumulator.duration.value + currentValue.distance.value
                    }
                })
                setDuration(totalDuration)

                // Calculate price

                const kilometer = totalDistance / 1000
                const trip_price = kilometer * parseInt(JSON.parse(size).price_per_km)
                const discount = 100 - parseInt(JSON.parse(size).bonus)
                const discount_price = discount / 100
                const actual_price = trip_price * discount_price + parseInt(JSON.parse(size).base_fair);

                setTotalPrice(Math.round(actual_price))

            })

        }

        const getPlaceDetails = async () => {

            // ORIGIN
            const response = await axios.get(`${geocodeJson}?address=${origin}&key=${apiKey}`)
            setAddressOne({
                longitude: response?.data?.results[0].geometry.location.lng,
                latitude: response?.data?.results[0].geometry.location.lat,
                placeId: response?.data?.results[0].place_id,
                address: response?.data?.results[0].formatted_address,
            })

            // DESTINATION 1
            const response2 = await axios.get(`${geocodeJson}?address=${destination}&key=${apiKey}`)
            setAddressTwo({
                longitude: response2?.data?.results[0].geometry.location.lng,
                latitude: response2?.data?.results[0].geometry.location.lat,
                placeId: response2?.data?.results[0].place_id,
                address: response2?.data?.results[0].formatted_address,
            })

            // DESTINATION 1
            const response3 = await axios.get(`${geocodeJson}?address=${destination2}&key=${apiKey}`)
            setAddressThree({
                longitude: response3?.data?.results[0].geometry.location.lng,
                latitude: response3?.data?.results[0].geometry.location.lat,
                placeId: response3?.data?.results[0].place_id,
                address: response3?.data?.results[0].formatted_address,
            })

        }

        fetch()
        getPlaceDetails()

    }, [origin, destination, destination2, size])

    const handleSubmit = async (e) => {

        e.preventDefault()

        let data

        if (offOne) {
            data = {
                categories,
                source: {
                    longitude: addressOne?.longitude,
                    latitude: addressOne?.latitude,
                    placeId: addressOne?.placeId,
                    address: addressOne?.address,
                },
                destinations: [
                    {
                        longitude: addressTwo?.longitude,
                        latitude: addressTwo?.latitude,
                        placeId: addressTwo?.placeId,
                        address: addressTwo?.address,
                    },
                    {
                        longitude: addressThree?.longitude,
                        latitude: addressThree?.latitude,
                        placeId: addressThree?.placeId,
                        address: addressThree?.address,
                    }
                ],
                distance,
                weight: '10kg',
                size_id: parseInt(JSON.parse(size).id),
                quantity,
                name: name + '|' + item2,
                payment
            }
        } else {
            data = {
                categories,
                source: {
                    longitude: addressOne?.longitude,
                    latitude: addressOne?.latitude,
                    placeId: addressOne?.placeId,
                    address: addressOne?.address,
                },
                destinations: [
                    {
                        longitude: addressTwo?.longitude,
                        latitude: addressTwo?.latitude,
                        placeId: addressTwo?.placeId,
                        address: addressTwo?.address,
                    }
                ],
                distance,
                weight: '10kg',
                size_id: parseInt(JSON.parse(size).id),
                quantity,
                name,
                payment
            }
        }

        const payload = {
            data,
            user
        }


        if (categories.length == 0) {
            toast.error('Please select item category')
        } else if (payment == '') {
            toast.error('Please select your payment option')
        } else if (offOne) {
            if (destination2 == '') {
                toast.error('Drop off Location two is required')
            } else if (item2 == '') {
                toast.error('Item Name for Drop off Location two is required')
            } else {
                Swal.fire({
                    title: 'Are you sure you want to proceed placing an order?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Request Delivery'
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(placeOrder(payload))
                    }
                })
            }
        } else {
            Swal.fire({
                title: 'Are you sure you want to proceed placing an order?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Request Delivery'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(placeOrder(payload))
                }
            })
        }

    }

    if (!isLoaded) {
        return <Loader />
    }

    return (

        <div className="row" style={{ paddingTop: '60px' }}>

            <div className="col-lg-6 order-2 order-lg-0">

                <form onSubmit={handleSubmit}>

                    <div className="delivery_details">

                        <div className="pickup_forms mt-4">

                            {/* PICKUP */}

                            <div class="form-group with_icon">
                                <div class="input_group">
                                    <Autocomplete options={mapOptions} className='w-100' onLoad={onLoadOrigin} onPlaceChanged={onPlaceChangeOrigin}>
                                        <input type="text" class="form-control" placeholder="Pick up location" required />
                                    </Autocomplete>
                                    <div class="icon">
                                        <i class=''><BiMapPin /></i>
                                    </div>
                                </div>
                            </div>

                            {/* DROP OFF */}

                            <div className="d-flex align-items-center">

                                <div className="w-100">
                                    <div class="form-group with_icon">
                                        <div class="input_group">
                                            <Autocomplete options={mapOptions} className='w-100' onLoad={onLoadDestination} onPlaceChanged={onPlaceChangeDestinationOne}>
                                                <input type="text" name='destination' class="form-control" placeholder="Drop off location One" required />
                                            </Autocomplete>
                                            <div class="icon">
                                                <i class=''><MdLocationPin /></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!offOne && (
                                    <div className="ms-2 mb-3">
                                        <button className="btn edit-btn rounded-pill mt-0" type="button" onClick={() => setOffOne(true)}><FiPlusCircle /></button>
                                    </div>
                                )}

                            </div>

                            <div class="form-group with_icon">
                                <div class="input_group">
                                    <input type="text" class="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="Item name No: 1" required />
                                    <div class="icon">
                                        <i class=''><FaBoxOpen /></i>
                                    </div>
                                </div>
                            </div>

                            {offOne && (

                                <>

                                    <div className="d-flex align-items-center">

                                        <div className="w-100">
                                            <div class="form-group with_icon">
                                                <div class="input_group">
                                                    <Autocomplete options={mapOptions} className='w-100' onLoad={onLoadDestination2} onPlaceChanged={onPlaceChangeDestinationTwo}>
                                                        <input type="text" name='destination2' class="form-control" placeholder="Drop off location Two" />
                                                    </Autocomplete>
                                                    <div class="icon">
                                                        <i class=''><MdLocationPin /></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ms-2 mb-3">
                                            <button type="button" className="btn del-btn" onClick={handleCancelBtn}> <IoMdRemoveCircle /> </button>
                                        </div>

                                    </div>

                                    <div class="form-group with_icon">
                                        <div class="input_group">
                                            <input type="text" class="form-control" placeholder="Item name No: 2" value={item2} onChange={(e) => setItem2(e.target.value)} />
                                            <div class="icon">
                                                <i class=''><MdLocationPin /></i>
                                            </div>
                                        </div>
                                    </div>

                                </>

                            )}

                            {/* SIZE */}
                            <select class="form-select my-4 py-2" onChange={(e) => setSize(e.target.value)} required>
                                <option value='' disabled selected> Select Size </option>
                                {sizes?.map((size) => (
                                    <option key={size.id} value={JSON.stringify(size)}> {size.dispatch_size} </option>
                                ))}
                            </select>

                            {/* CATEGORIES */}

                            <div class="form-group mt-3">
                                <label htmlFor=""> Categories </label>
                                <Multiselect
                                    isObject={false}
                                    onSelect={(e) => setCategory(e)}
                                    onRemove={(e) => setCategory(e)}
                                    selectedValues={categories}
                                    displayValue="Select Categories"
                                    placeholder='Select Categories'
                                    options={options}
                                    showCheckbox
                                />
                            </div>

                            <div class="form-group with_icon">
                                <div class="input_group">
                                    <input type="text" class="form-control" value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder="Quantity" required />
                                    <div class="icon">
                                        <i class=''><FaSortAmountUpAlt /></i>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <hr className="w-100" />

                    <div className="place_order">

                        <div className="d-flex align-items-center justify-content-end mb-2">

                            <div className="fw-bold me-3 text-primary">
                                <h4> <CurrencyFormat price={totalPrice} /> </h4>
                            </div>

                            <div className="d-flex col-7 payment">

                                <div className="me-2 w-100">
                                    <input type="radio" name="payment" id="cash" value='cash' onChange={(e) => setPayment(e.target.value)} />
                                    <label htmlFor="cash">Cash</label>
                                </div>

                                <div className="w-100" >
                                    <input type="radio" name="payment" id="wallet" value='wallet' onChange={(e) => setPayment(e.target.value)} />
                                    <label htmlFor="wallet">Wallet</label>
                                </div>

                            </div>

                        </div>

                        <div className="d-flex align-items-center justify-content-end">

                            <div className="fw-bold me-3">
                                {Math.round(distance / 1000)} km | 
                                 { duration/60 >= 120 ? Math.round(duration / 60 / 60) : Math.round(duration / 60) } { duration/60 >= 120 ? 'Hours' : 'Mins' }
                            </div>

                            <div className="form-group col-7">
                                <button type='submit' className="btn btn-primary w-100">Request Delivery</button>
                            </div>

                        </div>

                    </div>

                </form>

            </div>

            {/* PRICE */}

            <div className="col-lg-6 order-0 order-lg-2 mt-3 rounded-5">
                <GoogleMap
                    center={coordinates}
                    zoom={16}
                    // mapContainerStyle={{ width: '100%', height: '80vh' }}
                    mapContainerClassName='google_map'
                    options={{
                        zoomControl: true,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                    }}
                    onLoad={map => setMap(map)}
                >
                    {directionsResponse && (
                        <DirectionsRenderer directions={directionsResponse} />
                    )}
                </GoogleMap>
            </div>

        </div >

    )
}

export default PlaceOrder
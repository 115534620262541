import React, { useEffect } from 'react'
import './auth.css'
import Logo from '../../../assets/images/logo/logo.png'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik';
import { forgotPassword } from '../../../schemas';
import CustomInput from '../../../components/FormElements/CustomInput'
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { forgotten, reset } from '../../../feature/auth/authSlice';
import { BtnLoader } from '../../../components/BtnLoader';

function ForgotPasswordAuth() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    useEffect(() => {

        if (isError) {
            toast.error(message)
        }

        if (isSuccess) {
            navigate('/resetpassword')
            toast.success(message)
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    return (

        <div class="main-wrapper log-wrap">

            <div class="row">

                <div class="col-md-6 login-bg d-flex align-items-center">

                    <div className="welcome-login text-white">

                        <h4 className='mb-4'> Welcome Back to Run Delivery . </h4>

                        <p className='fw-light'> Business plan draws on a wide range of knowledge from different business disciplines. Business draws on a wide range of different business .</p>

                    </div>

                </div>

                <div class="col-md-6 login-wrap-bg">

                    <div class="login-wrapper">

                        <div class="loginbox">

                            <div class="img-logo">

                                <img src={Logo} class="img-fluid" alt="Logo" />

                                <div class="back-home">
                                    <Link to="/">Back to Home</Link>
                                </div>

                            </div>

                            <h1 className='fw-bold mb-4'> Forgot Password ? </h1>

                            <Formik
                                initialValues={{
                                    phone_number: '',
                                }}
                                validationSchema={forgotPassword}
                                onSubmit={(values, actions) => {
                                    dispatch(forgotten(values))
                                }}
                            >

                                {(props) => (

                                    <Form autoComplete='off'>

                                        <div class="form-group">
                                            <label class="form-control-label">Phone Number</label>
                                            <CustomInput name="phone_number" type="tel" placeholder="Enter Phone number" />
                                        </div>

                                        <div class="d-grid mt-5">
                                            <button class="btn btn-sm btn-primary fw-light fs-sm" type="submit" disabled={isLoading}> {isLoading ? <BtnLoader /> : 'Submit '} </button>
                                        </div>

                                    </Form>

                                )}

                            </Formik>

                        </div>

                        <div class="google-bg text-center">
                            <p class="mb-0"> Back to Login <Link className="fw-bold" to="/login">Sign In</Link></p>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    )
}

export default ForgotPasswordAuth
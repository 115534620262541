import React from 'react'
import { MdOutlineHome } from 'react-icons/md'
import { MdHistory } from 'react-icons/md'
import { BsPerson } from 'react-icons/bs'
import { IoIosWallet } from 'react-icons/io'
import { BsPlus } from 'react-icons/bs'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer class="un-bottom-navigation filter-blur">

            <div class="em_body_navigation border-0 -active-links">

                <div class="item_link">
                    <Link to="/dashboard" class="btn btn_navLink" aria-label="btnNavigation">
                        <div class="icon_current">
                            <i class="ri-home-5-fill"><MdOutlineHome /></i>
                        </div>
                        <div class="icon_active">
                            <i class="ri-home-5-fill"><MdOutlineHome /></i>
                        </div>
                    </Link>
                </div>

                <div class="item_link">
                    <Link to="/orders" class="btn btn_navLink" aria-label="btnNavigation">
                        <div class="icon_current">
                            <i class="ri-search-2-line"> <MdHistory /> </i>
                        </div>
                        <div class="icon_active">
                            <i class="ri-search-2-fill"> <MdHistory /> </i>
                        </div>
                    </Link>
                </div>

                <div class="item_link">
                    <button type="button" name="uploadItem" aria-label="uploadItem"
                        class="btn btn_navLink without_active" data-bs-toggle="modal" data-bs-target="#mdllUploadItem"
                        ariaLabel="btnNavigation">
                        <div class="btn btnCircle_default">
                            <i class="ri-add-line"> <BsPlus /> </i>
                        </div>
                    </button>
                </div>

                <div class="item_link">
                    <Link to="/wallet" class="btn btn_navLink" aria-label="btnNavigation">
                        <div class="icon_current">
                            <i class="ri-heart-3-line"> <IoIosWallet /> </i>
                        </div>
                        <div class="icon_active">
                            <i class="ri-heart-3-fill"> <IoIosWallet/> </i>
                        </div>
                    </Link>
                </div>

                <div class="item_link">
                    <Link to="/profile" class="btn btn_navLink" aria-label="btnNavigation">
                        <div class="icon_current">
                            <i class="ri-user-4-line"><BsPerson /></i>
                        </div>
                        <div class="icon_active">
                            <i class="ri-user-4-fill"><BsPerson /></i>
                        </div>
                    </Link>
                </div>

            </div>

        </footer>
    )
}

export default Footer
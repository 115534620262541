import React, { useEffect, useState } from 'react'
import UserProfile from './UserProfile'
import Dropzone from '../../../components/Dropzone'
import './auth.css'
import Header from '../../../components/dashboard/Header'
import Footer from '../../../components/dashboard/Footer'
import BGimage from './../../../assets/dashboard/images/BgImage.jpg'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { addCountry, getuser, reset } from '../../../feature/auth/authSlice'

function Profile({ country, countries }) {

    const { user, isLoading, message, isError, isSuccess, userProfile } = useSelector((state) => state.auth)

    const dispatch = useDispatch()

    const [country_id, setValue ] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            country_id,
            token: user?.token
        }
        dispatch(addCountry(data))
    }

    useEffect(() => {

        if (isError) {
            toast.error(message)
        }

        if (isSuccess) {
            toast.success(message)
            dispatch(getuser(user?.token))
        }

        dispatch(reset())

    }, [isError, isSuccess, message, dispatch])

    return (

        <div className='profile'>

            <Header />

            <div className='container' style={{ paddingTop: '100px' }}>

                <div className='row'>

                    <div className='col-lg-4 mb-5'>

                        <div class="card border-0 ">

                            <img src={BGimage} alt="placeholder" class="card-img-top" />

                            <div class="row justify-content-center">

                                <div class="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                                    <Dropzone token={user.token} user={userProfile} isLoading={isLoading} />
                                </div>

                            </div>

                            <div class="card-body pt-0 pb-4">

                                <div class="text-center mt-3">
                                    <h5 className='fw-bold mb-2'> {userProfile?.user?.first_name} {userProfile?.user?.last_name} </h5>
                                    <p className="tex-sm mb-0"> {userProfile?.user?.email} </p>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className='col-lg-8 card  border-0 py-4 rounded'>

                        <div class="px-3 mb-4">
                            <p class="mb-0 fw-bold">Edit Profile</p>
                        </div>


                        <div class="card-body">

                            <p class="text-uppercase text-sm mb-3">User Information</p>

                            {/* USER INFO */}

                            <UserProfile token={user.token} user={userProfile?.user} isLoading={isLoading} />

                            {/* USER INFO END */}

                            <hr class="horizontal dark" />

                            {/* COUNTRY */}

                            {country === null && (
                                <form onSubmit={handleSubmit}>
                                    <div class="form-group">
                                        <label for="example-text-input" class="form-control-label"> Country </label>
                                        <select name="country_id" id="country_id" className="form-control" required value={country_id} onChange={(e) => setValue(e.target.value)}>
                                            <option value="" selected={true}> -- Select Country -- </option>
                                            {countries?.map((data) => (
                                                <option value={data.id} key={data.id}> {data.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type='submit' className="btn btn-primary rounded-pill"> Save Country </button>
                                </form>
                            )}


                            {/* <p class="text-uppercase text-sm my-3">Password</p>

                            <UpdatePassword /> */}

                            {/* PASSWORD END */}

                        </div>

                    </div>

                </div>

            </div>

            <Footer />

        </div>

    )
}

export default Profile
import React from 'react'
import Footer from '../../components/dashboard/Footer'
import Header from '../../components/dashboard/Header'
import PlaceOrder from './PlaceOrder'

const Index = ({ country }) => {

  return (
    <div className="place_order_container">

      <Header />

      <div className="container-fluid">

          <PlaceOrder country={country} />

      </div>

      <Footer />

    </div>
  )
}

export default Index
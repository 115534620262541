import React from 'react'
import { Link } from 'react-router-dom'
import { AiFillCloseCircle } from 'react-icons/ai'
import Logo from '../../assets/images/logo/logo.png'
import { AiOutlineUser } from 'react-icons/ai'
import { useSelector } from 'react-redux'

function MobileSlider() {

    const { user } = useSelector((state) => state.auth)

    return (

        <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
            <div class="offcanvas-header">

                <Link to='/' >
                    <img src={Logo} alt="Logo" width={100} />
                </Link>

                <span style={{ fontSize: '26px', color: '#000' }} data-bs-dismiss="offcanvas" aria-label="Close" > <AiFillCloseCircle /> </span>

            </div>

            <div class="offcanvas-body mt-5">

                <div className="navbar-collapse" id="navbarSupportedContent">

                    <ul className="navbar-nav m-auto mb-2 mb-lg-0 text-uppercase">

                        <li className="nav-item">
                            <Link className="nav-link" to="/">
                                HOME
                            </Link>
                        </li>

                        {/* <li className="nav-item">
                            <a className="nav-link" href="https://dashboard.rundeliverys.com/ride-for-run" target='_blank' rel='noreferrer'>
                                BECOME A RUN CHAMPION
                            </a>
                        </li> */}

                        <li className="nav-item">
                            <Link className="nav-link" to="/ride-for-run">
                                BECOME A RUN CHAMPION
                            </Link>
                        </li>

                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/investment" >
                                Investment
                            </Link>
                        </li> */}

                        <li className="nav-item">
                            <Link className="nav-link" to="/rep-for-run" >
                                Rep-for-run
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to='/terms'>
                                PRIVACY/TERMS OF SERVICE
                            </Link>
                        </li>

                    </ul>

                    <div className="nav-side">

                        <div className="d-flex align-items-center">

                            {!user ?

                                <Link to='/login' className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold me-4">
                                    <span> Login  <i className="bi bi-arrow-right ms-1">      <AiOutlineUser /></i> </span>
                                </Link>

                                :

                                <Link to='/dashboard' className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold me-4">
                                    <span> Dashboard </span>
                                </Link>



                            }

                            <Link to='/contact' className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold">
                                <span> Contact Us  <i className="bi bi-arrow-right ms-1"></i> </span>
                            </Link>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    )
}

export default MobileSlider
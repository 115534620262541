import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../components/dashboard/Footer'
import Header from '../../components/dashboard/Header'
import OrderList from '../../components/dashboard/OrderList'
import { getOrder, orderReset } from '../../feature/orders'
import toast from 'react-hot-toast';

const OrdersPage = () => {

    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { orders, orderSuccess, orderError, orderMessage } = useSelector((state) => state.orders)

    useEffect(() => {

        dispatch(getOrder(user?.token))

        if(orderSuccess){
            dispatch(getOrder(user?.token))
            toast.success(orderMessage)
        }

        dispatch(orderReset())

    }, [dispatch, user, orderSuccess, orderError, orderMessage])

    return (

        <div className="profile">

            <Header />

            <div className="container-fluid">

                <section class="un-blog-list" style={{ paddingTop: '80px' }}>

                    <div class="content">
                        <ul class="nav flex-column">

                            <div class="row">

                                <div class="col-12">

                                    <div class="card mb-4">

                                        <div class="card-body py-3 shadow rounded rounded">

                                                <OrderList trips={orders} token={user?.token} />

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </ul>
                    </div>
                </section>

            </div>

            <Footer />

        </div>

    )
}

export default OrdersPage
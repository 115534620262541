import React from 'react'
import { Form, Formik } from 'formik';
import CustomInput from '../../../components/FormElements/CustomInput'
import { ProfileSchema } from '../../../schemas/index'
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../feature/auth/authSlice';
import { BtnLoader } from '../../../components/BtnLoader';

function UserProfile({ user, token, isLoading }) {

    const dispatch = useDispatch()

    return (

        <div className="">

            <Formik
            enableReinitialize={true}
                initialValues={{
                    first_name: user && user.first_name,
                    last_name: user && user.last_name,
                    home_address: user && user.home_address
                    // email: '',
                    // phone: '',
                    // address: '',
                }}
                validationSchema={ProfileSchema}
                onSubmit={(values, actions) => {
                    const data = {
                        token: token,
                        values
                    }
                    dispatch(updateUser(data))
                }}
            >

                {(props) => (

                    <Form autoComplete='off'>

                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input" class="form-control-label"> First Name </label>
                                    <CustomInput name="first_name" type="text" placeholder="First Name" />
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input" class="form-control-label"> Last Name </label>
                                    <CustomInput name="last_name" type="text" placeholder="Last Name" />
                                </div>
                            </div>

                            {/* <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input" class="form-control-label"> Email Address </label>
                                    <CustomInput name="email" type="email" placeholder="Email address" />
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input" class="form-control-label"> Phone Number </label>
                                    <CustomInput name="phone" type="tel" placeholder="Phone Number" />
                                </div>
                            </div> */}

                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-text-input" class="form-control-label"> Home Address </label>
                                    <CustomInput name="home_address" type="text" placeholder="home Address" />
                                </div>
                            </div>

                        </div>

                        <button type='submit' style={{ fontSize: '13px' }} className="btn btn-primary btn-sm rounded-pill" disabled={isLoading}> { isLoading ? <BtnLoader /> : 'Update user' }</button>

                    </Form>
                )}

            </Formik>


        </div>

    )
}

export default UserProfile
import * as Yup from 'yup';

export const LoginSchema = Yup.object({
  email: Yup.string().email('Email address is invalid').required('Email address is required'),
  password: Yup.string().required('Password is required'),
});

export const forgotPassword = Yup.object({
  phone_number: Yup.string().required('Phone number is required'),
});


export const RegistrationSchema = Yup.object({
  email: Yup.string().email('Email address is invalid').required('Email address is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  phone_number: Yup.string().required('Phone is required'),
  password: Yup.string().required('Password is required').min(5, 'Password must be at least 5 characters long'),
  country_id: Yup.string().required('Select your country'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password does not match').required('Password Confirmation is required'),
  accept: Yup.boolean().oneOf([true], 'Please accept terms and conditions')
});

export const ProfileSchema = Yup.object({
  // email: Yup.string().email('Email address is invalid').required('Email address is required'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  home_address: Yup.string().required('Home Address is required'),
  // address: Yup.string().required('Home address is required'),
});


export const ResetPasswordSchema = Yup.object({
  otp: Yup.string().required('Reset OTP is required').min(5, 'OTP must be 5 characters long'),
  phone_number: Yup.string().required('Phone number is required'),
  password: Yup.string().required('Password is required').min(5, 'Password must be at least 5 characters long'),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Password does not match').required('Password Confirmation is required'),
});

export const VerifySchema = Yup.object({
  otp: Yup.string().required('Reset OTP is required').min(4, 'OTP must be 4 characters long or more'),
  phone_number: Yup.string().required('Phone is required')
});

export const updatePassword = Yup.object({
  currentPassword: Yup.string().required('Current Password is required'),
  password: Yup.string().required('Password is required').min(5, 'Password must be at least 5 characters long'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password does not match').required('Password Confirmation is required'),
});


export const contactSchema = Yup.object({
  email: Yup.string().email('Email address is invalid').required('Email address is required'),
  name: Yup.string().required('Name is required'),
  phone: Yup.string().required('Phone number is required'),
  message: Yup.string().required('Message is required'),
});


export const LoanInfoSchema = Yup.object({
  employmentStatus: Yup.string().required('Employment status is required'),
  lga: Yup.string().required('Local goverment area is required'),
  state: Yup.string().required('State is required'),
  income: Yup.string().required('Monthly Income is required'),
  dob: Yup.string().required('Date of Birth is required'),
});